<template>
    <div>
        <h3 class="notice-title">
            <span class="title-txt">新闻动态</span><br>
            <span class="title-eng">News Center</span>
        </h3>
        <div class="notice-detail">

            <h2 class="notice-detail-title">2019年河南省中小学生科技体育竞赛暨科技体育社团展评活动举行</h2>
            <p  class="notice-detail-content">
                11月16日，为期一天半的 “2019年河南省中小学生科技体育竞赛暨科技体育社团展评活动”在郑州市二七区政通路小学开幕！本次活动由河南省教育厅主办，河南省学生体育总会科技体育协会承办，郑州市二七区教体局、郑州市二七区政通路小学协办。河南省教育厅总督学李金川，河南省教育厅体卫艺处处长聂世忠，河南省学生体育总会副秘书长、科技体育协会秘书长吕刚，郑州市教育局体卫艺处处长高百中，郑州市二七区教育体育局副局长张卫民等领导参加了此次活动，河南省学生体育总会副秘书长、科技体育协会秘书长吕刚主持了活动开幕式。
            </p>
            <p  class="notice-detail-content">
                职业教育与普通教育是两种不同教育类型，具有同等重要地位。改革开放以来，职业教育为我国经济社会发展提供了有力的人才和智力支撑，现代职业教育体系框架全面建成，服务经济社会发展能力和社会吸引力不断增强，具备了基本实现现代化的诸多有利条件和良好工作基础。随着我国进入新的发展阶段，产业升级和经济结构调整不断加快，各行各业对技术技能人才的需求越来越紧迫，职业教育重要地位和作用越来越凸显。
            </p>
            <p  class="notice-detail-content">
                本次活动以“科技启迪智慧、体育健康人生”为主题，共设科技体育竞赛和社团展示评比活动两大板块。其中，竞赛项目设立了航海模型、建筑模型、车辆模型、航空模型和校园未来工程师智能机器人赛等多种项目。来自郑州、开封、洛阳、新乡、焦作等全省各地的89支代表队1900余名参赛队员展开激烈比拼。
            </p>
            <img src="@/assets/images/3-1.jpg" alt="">
            <img src="@/assets/images/3-2.jpg" alt="">
            <img src="@/assets/images/3-3.jpg" alt="">
            <img src="@/assets/images/3-4.jpg" alt="">
            <img src="@/assets/images/3-5.jpg" alt="">
            <img src="@/assets/images/3-6.jpg" alt="">
            <img src="@/assets/images/3-7.jpg" alt="">
            <img src="@/assets/images/3-8.jpg" alt="">
            <img src="@/assets/images/3-9.jpg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetailThree"
    }
</script>
<style scoped>
    .notice-title{
        margin-top: 33px;
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 40px;
    }
    .title-txt{
        color: #1980FF;
        font-size: 30px;
    }
    .title-eng{
        position: relative;
        color: #3F3F3F;
        font-size: 20px;
    }
    .title-eng:before{
        content: "";
        position: absolute;
        left: 20px;
        bottom: -10px;
        display: inline-block;
        width: 80px;
        height: 4px;
        background-color: #1980FF;
    }
    .notice-detail{
        width: 865px;
        min-height: 300px;
        margin:40px auto 100px auto;
    }
    .notice-detail img{
        margin-bottom: 10px;
    }
    .notice-detail-title{
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color:#3F3F3F;
        font-weight: bold;
    }
    .notice-source{
        margin-top: 20px;
        color:#3F3F3F;
    }
    .notice-detail-content{
        margin-top: 30px;

        line-height: 30px;
        font-size: 14px;
        color:#000;
    }
</style>
