<template>
    <div>
      <div class="unit-info">
        <p class="txt-info">为全面贯彻落实中共中央办公厅、国务院办公厅《关于全面加强和改进新时代学校体育工作的意见》，国务院办公厅《体育强国建设纲要》，体育总局、教育部《关于深化体教融合促进青少年健康发展的意见》有关精神，推进我省中小学科技体育活动的深入开展，提高中小学生综合素养，展示我省学校科技体育创新成果，举办 2021年河南省中小学生科技体育系列竞赛。</p>
        <div class="unit-wrap" >
          <div class="unit-item" v-for="(item,index) in unitData" :key="index">
            <img :src="require('@/assets/images/unit'+index+'.png')" class="unit-item-img" alt="" width="120" height="130">
            <span class="zb-unit-txt">{{item.title}}</span>
            <div class="zb-unit-item" v-for="(childItem,childIndex) in item.chilrden" :key="childIndex">
              <p>{{childItem}} </p>
            </div>
          </div>
        </div>

      </div>
      <div class="date-address">
        <div class="date-address-wrap">
          <h3 class="date-address-title">竞赛时间和地点</h3>
          <div class="js-time-wrap">
            <div class="js-time-item" v-for="(item,index) in jsTime" :key="index">
              <div class="title-txt">{{item.title}}</div>
              <span class="time-txt">{{item.time}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="date-address js-object">
        <div class="date-address-wrap js-object-wrap">
          <h3 class="date-address-title">竞赛对象</h3>
          <p class="des">各省辖市、济源示范区、直管试点县（市）中小学有正式学籍在校学生（含职专）</p>
          <div class="js-object-con">
            <div class="js-object-item" v-for="(item,index) in jsObject" :key="index">
              <div class="title-txt">{{item.title}}</div>
              <div class="js-txt-item">
                <span v-for="(itemChild,indexChild) in item.chilrden" :key="indexChild">{{itemChild}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sx-set">
        <h3 class="sx-set-title">赛项设置</h3>
        <div class="sx-set-img">
          <el-carousel :interval="4000" type="card" height="260px">
            <el-carousel-item v-for="(item,index) in prizeImg" :key="index">
              <!--<span style="color:#fff">文字信息</span>-->
              <img :src="require('@/assets/images/'+item)" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="jiangli">
        <div class="jiangli-wrap">
          <h3 class="jiangli-title">奖励办法</h3>
          <div class="jiangli-content">
            <div class="jiangli-left">
              <img src="@/assets/images/mc.png" alt="" width="100" height="85" class="jiangli-img">
              <h3 class="mc-title">优秀运动员</h3>
              <p class="mc-txt">各竞赛项目分设一二、三等奖未获得等级奖的选手颁发优胜奖</p>
            </div>
            <div class="jiangli-mid">
              <img src="@/assets/images/yx.png" alt="" width="100" height="85" class="jiangli-img">
              <h3 class="mc-title mc-mid-title">优秀辅导教师</h3>
              <p class="mc-txt mc-mid-txt">辅导学生获得一项第一名或两项前三名成绩可评选“科技体育社团优秀辅导教师</p>
            </div>
            <div class="jiangli-right">
              <img src="@/assets/images/td.png" alt="" width="100" height="85" class="jiangli-img">
              <h3 class="mc-title">优秀学校代表</h3>
              <p class="mc-txt mc-right-txt">“红心向党 匠心育人”红色主题教育竞赛活动中成绩突出的学校</p>
            </div>
          </div>
        </div>
      </div>
      <div class="method">
        <h3 class="sx-set-title">参赛方法</h3>
         <div class="method-content">
           <div class="method-one">
             <span class="num">1</span>
             <span  class="method-item-title">提交资料报名</span>
             <p class="method-txt">
               2021年11月5日前 各省辖市教育行政部门组织，以学校为单位报名 <br><br>通将excel格式报名表报至13403716656@163.com
             </p>
           </div>
           <div  class="method-one">
             <span class="num">2</span>
             <span  class="method-item-title">资格审查</span>
             <p class="method-txt">
               1.打印并盖有公章的纸质报名表（须与电子报名表一致）<br> 2.参赛选手身份证明或学籍证明<br>  3.参赛选手健康证明 <br> 4．自愿参赛责任及风险告知书 <br> 5.人身意外伤害保险单据（含路途和比赛期间）<br>
             </p>
           </div>
           <div  class="method-one">
             <span class="num">3</span>
             <span  class="method-item-title">报道参赛</span>
             <p class="method-txt">
               根据各赛项要求准备比赛资料，参赛队请于2021年11月26日15：00-18:00，到郑州市第八十四中学（郑州市郑东新区万通街与东周路交叉口）报到。
             </p>
           </div>
         </div>
      </div>
      <div class="fj-load">
        <div class="fj-load-wrap">
          <h3 class="sx-set-title black-line">附件下载</h3>
          <p class="fj-item" v-for="(item,index) in fjData" :key="index">
            <span class="img-icon"><img src="@/assets/images/pdf.png" alt=""></span>
            <a :href="item.hrefUrl" class="fj-txt">{{item.label}}</a>
          </p>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "index",
       data(){
          return{
            unitData:[{
              title:"主办单位",
              chilrden:['河南省教育厅']
            },{
              title:"承办单位",
              chilrden:['河南省学生体育总会科技体育分会','郑州市郑东新区教体局','郑州市第八十四中学']
            },{
              title:"协办单位",
              chilrden:['河南行知塾教育科技集团']
            }],
            jsTime:[{
              title:'竞赛时间',
              time:'2021年11月26-28日'
            },{
              title:'竞赛地点',
              time:'郑州市第八十四中学'
            }],
            jsObject:[{
              title:"一至六年级",
              chilrden:["小学男子组","小学女子组"]
            },{
              title:"初一至初三",
              chilrden:["中学男子组","中学女子组"]
            }],
            prizeImg:["huodong0.png","huodong1.png","huodong2.png"],
            fjData:[{
              label:" 河南省教育厅办公室关于举办2021年河南省中小学生科技体育系列竞赛通知",
              hrefUrl:`${this.$axios.iconUrl}/uploads/jingsai/河南省教育厅办公室关于举办2021年河南省中小学生科技体育系列竞赛通知.pdf`
            },{
              label:"2021年河南省中小学生科技体育系列竞赛规程",
              hrefUrl: `${this.$axios.iconUrl}/uploads/jingsai/附件1：2021年河南省中小学生科技体育系列竞赛规程.docx`,
            },{
              label:"自愿参赛责任及风险告知书",
              hrefUrl:`${this.$axios.iconUrl}/uploads/jingsai/附件2：自愿参赛责任及风险告知书.docx`
            },{
              label:"2021年河南省中小学科技体育系列竞赛报名表",
              hrefUrl:`${this.$axios.iconUrl}/uploads/jingsai/附件3：2021年河南省中小学科技体育系列竞赛报名表.docx`
            }]
          }
       }
    }
</script>

<style scoped>
.unit-info{
  width: 1200px;
  margin: 30px auto 60px auto;
}
  .txt-info{
    height: 108px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
  }
  .unit-wrap{
    display: flex;
    justify-content:space-between;
  }
  .unit-item{
    margin-top:30px;
    width:300px;
    text-align: center;
  }
  .unit-item-img{
    vertical-align: middle;
  }
  .zb-unit-txt{
    font-size: 30px;
    font-weight: bold;
    color: #000000;
  }
  .zb-unit-item{
    margin-top:20px;
  }
  .zb-unit-item p{
    line-height: 36px;
    font-size: 20px;
    color: #000000;
  }
  .date-address-wrap{
    width: 1200px;
    margin:0 auto;
  }
.date-address{
  width: 1920px;
  height: 650px;
  background:url("../../../assets/images/bg-img.png") no-repeat;
  }
.date-address .date-address-title{
  margin: 0 auto;
  padding-top: 20px;
  width: 500px;
  height:180px;
  line-height: 180px;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 3px solid rgba(255,255,255,0.2);
  }
.js-time-wrap{
  display: flex;
}
.js-time-item{
  margin-right:40px;
  margin-top:89px;
  padding:105px 0 0 50px;
  width: 520px;
  height: 155px;
  background-color: #FFFFFF;
  border-radius: 20px;
  background-image:url("../../../assets/images/time.png");
  background-repeat: no-repeat;
  background-position: 320px 46px;
}
.js-time-item:nth-child(2){
  background-image:url("../../../assets/images/add.png");
}
.js-time-item .title-txt{
  margin-bottom: 14px;
  text-align: center;
  width: 150px;
  height: 50px;
  font-size: 34px;
  font-weight: bold;
  line-height: 50px;
  border: 1px solid #1980FF;
  color: #1980FF;
}
.js-time-item .time-txt{
  font-size: 30px;
  font-weight: bold;
  color: #000000;
}
  .js-object{
    background-image:url("../../../assets/images/bolang-bg.png");
    background-repeat: no-repeat;
   background-position: bottom;
    height: 588px;
  }
  .js-object .date-address-title{
    margin:100px auto 25px auto;
    height:50px;
    line-height: 50px;
    font-size: 34px;
    font-weight: bold;
    color: #000000;
    border-bottom: none;
  }
  .js-object-wrap .des{
    text-align: center;
    font-size: 20px;
    color: #000000;
  }
  .js-object-wrap .title-txt{
    margin: 90px auto 0 auto;
    text-align: center;
    width: 240px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    background: #ECF7FF;
    border: 1px solid #1980FF;
    opacity: 1;
    border-radius: 10px;
    color: #000000;
    cursor: pointer;
  }
.js-object-wrap .title-txt:hover{
  background: #1980FF;
  color: #FFFFFF;
}
.js-object-con{
  display: flex;
  justify-content: space-between;
}
.js-object-item{
  background-image:url("../../../assets/images/zhishi.png");
  background-repeat: no-repeat;
  background-position: 100px 156px;
}
.js-txt-item{
  margin-top:109px;
}
.js-txt-item span{
   display: inline-block;
  text-align: center;
  width: 240px;
  height: 90px;
  line-height: 90px;
  border: 1px solid #1980FF;
  border-radius: 10px;
  font-size: 34px;
  font-weight: bold;
  color: #000000;
  }
.js-txt-item span:first-child{
  margin-right: 80px;
}
.sx-set{
  margin-top: 100px;
}
  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:100px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .sx-set-img{
    width: 800px;
    margin: 88px auto 0 auto;
  }
  .jiangli{
    width: 1920px;
    height: 650px;
    background:url("../../../assets/images/jiangli_bg.png") no-repeat;
  }
  .jiangli-wrap,
  .method{
    width:1200px;
    margin:100px auto 0 auto;
  }
  .jiangli-title{
    padding-top:100px;
    padding-bottom:52px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .jiangli-content{
    display: flex;
  }
  .jiangli-left,
  .jiangli-mid,
  .jiangli-right{
    width: 400px;
    height: 374px;
    background: rgba(25,128,255,0.5);
  }
  .jiangli-img{
    margin: 73px auto 0 auto;
    display: inherit;
  }
.jiangli-mid{
  background: #FFFFFF;
  border-bottom: 6px solid #1980FF;
  box-sizing: border-box;
}
.mc-title{
  margin-top:27px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #FFFFFF;
}
.mc-txt{
  text-align: center;
  width: 355px;
  margin:27px auto 0 auto;
  font-size: 20px;
  color: #FFFFFF;
}
  .mc-mid-title{
    color: #000000
  }
.mc-mid-txt{
  color: #3F3F3F
}
  .mc-right-txt{
    width: 355px;
  }
  .method-content{
    display: flex;
    justify-content: space-between;
    margin-top:90px;
  }
  .method-one{

  }
.method-one .num{
  display:inline-block;
  text-align: center;
  color: #1482E6;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #1980FF;
  border-radius: 50%;
  font-size: 34px;
}
.method-one .method-item-title{
  margin-left:27px;
  font-size: 34px;
  font-weight: bold;
  color: #000000;
}
.method-one .method-txt{
  line-height: 30px;
  margin-top: 21px;
  width: 320px;
  font-size: 20px;
  color: #3F3F3F;
}
  .fj-load{
    border: 1px solid #fff;
    margin-top: 90px;
    margin-bottom: 65px;
  background: url("../../../assets/images/bottom-bg.png") no-repeat;
  }
  .black-line{
    border-bottom: 1px solid #000;
    margin-bottom:80px;
  }
  .fj-load-wrap{
    width:1290px;
    height:540px;
    margin:85px auto 0 auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 30px 30px 10px rgba(0,0,0,0.16);
  }
  .fj-item{
   margin-left:169px;
    margin-top:20px;
  }
.fj-item .img-icon{
  vertical-align: middle;
}
  .fj-item .fj-txt{
    margin-left:60px;
 color:#1482E6;
    font-size: 20px;
  }

</style>
