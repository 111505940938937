<template>
<div>
  <div class="zpsb-wrap">
    <p class="txt-info">造物智能设计比赛对于培养学生学习与编程技术，激发创新思维潜能，提高综合设计和编程的能力，培养学生开展科学研究基本素质极为有益。 本届造物智能设计比赛主题选定为“智慧城市”，旨在促进青少年了解所在城市中的社区、学校、家庭中存在着哪些需要关注的现象或急需解决的问题，是否能用程序思维及技术手段给出相应的解决方案，让我们的“城市”变得更加科学、便捷、美好与舒适。参赛作品的控制器须根据作品类别和功能需要，使用大赛指定的开发板进行设计和创作，《比赛详情参考附件》。</p>
    <div class="zpsb-info">
      <span class="sb-btn" @click="handleWorksReport">作品申报</span><br>
      <a  class="sb-link" @click="handleOnload">点击附件下载</a><br>
      <span class="sb-time">申报截止时间：2021年10月29日23:59:59</span>
    </div>
  </div>
  <div class="jsdx-wrap">
    <div class="jsdx-content">
      <h3 class="sx-set-title" style="padding-top:40px">竞赛对象</h3>
      <p class="desc"><span>各省辖市、济源示范区、直管试点县（市）中小学有正式学籍在校学生（含职专）</span></p>
    <div class="jsdx-flex">
     <div class="jsdx-flex-con">
       <img class="con-img" src="@/assets/images/school2.png" alt="">
       <span class="xxz">小学组</span>
       <div  class="xxz-txt">
         每组学生人数限定2人 每名学生限报名参加一组，每组限报1项参赛作品，须且仅限配备1名指导教师。
       </div>
     </div>
      <div class="jsdx-flex-con">
        <img class="con-img" src="@/assets/images/school3.png" alt="">
        <span class="xxz">中学组</span>
        <div  class="xxz-txt">
          每组学生人数限定2人 每名学生限报名参加一组，每组限报1项参赛作品，须且仅限配备1名指导教师。
        </div>
      </div>
    </div>
      <p class="js-txt-waring">*不允许跨学段申报，申报学段有误，将取消比赛资格</p>
    </div>
  </div>
  <div class="zplx-wrap">
    <h3 class="sx-set-title zplx-title">作品类型</h3>
    <div class="jsbz-item-wrap">
      <div class="jsbz-item" v-for="(item,index) in jsdzItem" :key="index">
        <div class="jsbz-item-con">
          <h3 class="jsbz-item-title">
            <img :src="require('@/assets/images/aplx'+index+'.png')" alt="">
            {{item.title}}
          </h3>
          <div class="jsbz-item-txt">{{item.txt}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="jscx-wrap">
    <h3 class="sx-set-title border-none">竞赛程序</h3>
    <img src="@/assets/images/jstz.png" alt="">
  </div>
</div>
</template>

<script>
    export default {
        name: "fourthSJ",
      data(){
        return {
          jsdzItem:[{
            title:"工程应用类",
            txt:"针对学习与生活中发现的问题和需求，以及对工业、农业、森林海洋、交通运输、公共服务等社会各行业的观察与思考，设计实现能够利用智能手段解决问题或改进现有解决方式的作品"
          },{
            title:"人文艺术类",
            txt:"运用声、光、触控效果、交互体验等智能技术，展现艺术思考、艺术体验或人文思想、历史文化、民族风采等内容的作品"
          },{
            title:"科学探索类",
            txt:"运用声、光、触控效果、交互体验等智能技术，展现艺术思考、艺术体验或人文思想、历史文化、民族风采等内容的作品"
          }]
        }
      },
      methods:{
        handleWorksReport(){
          //选择判断是否登录，只有登录才能作品申报
          if(window.localStorage.token){
            this.$router.push({ path: '/jingsai/worksReport', query: { param: 4 }})
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
          }
        },
        handleOnload(){
          if(window.localStorage.token){
            window.open("");
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
            return false;
          }
        }
      }
    }
</script>

<style scoped>
  .zpsb-wrap,
  .jsdx-content{
    width:1200px;
    margin:0 auto;
  }
  .txt-info{
    margin-top: 39px;
    text-indent: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
  }
  .zpsb-info{
    margin-top:24px;
    text-align: center;
    line-height: 50px;
  }
  .sb-btn{
    display: inline-block;
    font-size: 30px;
    margin-bottom: 8px;
    width:300px;
    height:80px;
    line-height: 80px;
    text-align: center;
    color:#fff;
    background-color: #ff7e24;
    border-radius: 10px;
    cursor: pointer;
  }
  .sb-link{
    font-size: 20px;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }
  .sb-link:link{
    border-bottom:1px solid #000;
  }
  .sb-time{
    font-size: 20px;
  }
  .jsdx-wrap{
    background-color: #F7F9FE;
    margin-top: 100px;
  }
  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:140px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .jsdx-content .desc{
    position: relative;
    top: -50px;
    text-align: center;
    color:#000000;
    font-size: 20px;
  }
  .js-txt-waring{
    color:#1980FF;
    font-size: 20px;
    text-align: center;
    margin-top: 48px;
    padding-bottom: 30px;
  }
  .jsdx-flex{
   display: flex;
    justify-content: space-between;
  }
  .jsdx-flex-con{
    margin-top: 30px;
  text-align: center;
  }
  .con-img{

  }
  .xxz{
    margin-left:60px;
    font-size: 30px;
    color: #000;
  }
  .xxz-txt{
    padding:28px 50px;
    margin-top: 44px;
    width: 400px;
    height: 130px;
    line-height: 30px;
    text-align: left;
    border: 1px solid #1980FF;
    font-size: 20px;
    color: #000;
  }
  .zplx-wrap{
    margin-top: 100px;
    width: 1920px;
    height:750px;
    background: url("../../../assets/images/bg-img.png") no-repeat;
    background-size: 1920px 750px;
    border: 1px solid #fff;
  }
  .zplx-title{
    margin-top:60px;
    height:100px;
    color:#fff;
    border-bottom: 1px solid rgba(255,255,255,0.6);
  }
  .jsbz-item-wrap{
    width: 1200px;
    margin:32px auto 0 auto;
    display: flex;
    justify-content: space-around;
  }
  .jsbz-item{
    width: 350px;
    height: 420px;
    background-color: #fff;
    color: #000;
  }
  .jsbz-item-con{

  }
  .jsbz-item-title{
    position: relative;
    padding-left: 110px;
    background-color:#1980FF ;
    height: 80px;
    line-height: 80px;
    font-size: 34px;
    color:#fff;
  }
  .jsbz-item-title img{
    position: absolute;
    left:30px;
    top: 10px;
  }
  .jsbz-item-txt{
    width:280px;
    margin: 60px auto 0  auto;
    font-size: 20px;
  }
  .jscx-wrap{
    text-align: center;
    margin-top: 100px;
  background: url("../../../assets/images/jscx-bg.png") no-repeat;
    height:1600px;
  }
  .border-none{
    border-bottom:none;
  }
</style>
