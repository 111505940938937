<template>
<div class="loginbox">
  <!-- 用户端登录界面 -->
  <div class="Login_BOX">
    <div class="Login_Logo">
      <img src="../images/yocode.png" alt="" draggable="false" />
    </div>
    <div class="Login_Box">
      <div class="loginlogo">
        <img src="../images/YOYOxiaocheng.png" alt="" draggable="false" />
      </div>
      <div class="Login_box">
        <div class="logininput">
          <!-- 用户名登录 -->
          <!-- <h2>游研视界</h2>
          <form>
            <input type="text" name="username" v-model="username" placeholder="请输入您的用户名" class="input-1" maxLength="11" />
            <div style="position:relative">
              <input :type="this.registration_data.pwdType" name="password" v-model="password" placeholder="请输入您的密码" class="input-2" />
              <img style="position:absolute;right:20px;top:15px;width:40px;" :src="this.registration_data.src" alt="" @click="changeType()" draggable="false">
            </div>
            <el-button style="outline:none;border:none;background:none;margin-top: 33px;margin-left:-26px;" @click="LoginIn"><img class="Loginin" src="../images/loginbutton.png" alt="" draggable="false"/></el-button>
          </form> -->
          <!-- 微信与用户名登录 -->
          <div v-if="theShow">
            <ul class="list-box">
              <li class="liActive" v-for="(item,index) in list"  :class="{active:num==index}" :key="item.index" @click="getNum(index)">{{item}}</li>
            </ul>
            <div v-show="aa">
              <div id="erweima" style="width:215px;height:215px;margin:20px 0 0 30px;"></div>
              <p style="margin-top:50px;font-size: 20px;font-family: PingFang SC;font-weight: 500;line-height: 33px;color: #333333;opacity: 1;text-align:center;">请使用微信扫描二维码</p>
              <p style="font-size: 18px;font-family: PingFang SC;font-weight: 500;line-height: 20px;color: #333333;opacity: 1;text-align:center;">立即打开思维世界的大门哟</p>
            </div>
            <div v-show="bb">
              <form>
                <input type="text" name="username" v-model="username" placeholder="请输入您的用户名" class="input-1" maxLength="11" />
                <div style="position:relative">
                  <input :type="this.registration_data.pwdType" name="password" v-model="password" placeholder="请输入您的密码" class="input-2" />
                  <img style="position:absolute;right:20px;top:15px;width:40px;" :src="this.registration_data.src" alt="" @click="changeType()" draggable="false">
                </div>
                <div class="waring">
                  没有账号？<span @click="handleJumpReg">立即注册</span>
                </div>
                <el-button style="outline:none;border:none;background:none;margin-top: 33px;margin-left:-26px;" @click="LoginIn"><img class="Loginin" src="../images/loginbutton.png" alt="" draggable="false"/></el-button>
              </form>
            </div>
          </div>
          <!-- <div v-if="isShow">
            <h2>还差一步：关联微信</h2>
            <div class="relationWX">
              <p>小朋友你的学号还没有关联微信哟</p>
              <img class="width:16px;height:16px;" src="../images/xiao.png" alt="" draggable="false">
            </div>
            <div id="ErWeiMa" style="width:180px;height:180px;border: 1px solid #707070;margin:20px auto;"></div>
            <p class="WXsaom" style="text-align:center;">请使用微信扫描二维码</p>
            <p style="text-align:center;font-size:24px;font-family:PingFang SC;font-weight:500;line-height:38px;color:#FFCB31;text-decoration:underline;opacity:1;cursor:pointer;" @click="ToCurriculum">下次一定</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from "axios";
import Qs from "qs";
export default {
  data() {
    return {
      username: '',
      password: '',
      registration_data:{
        pwdType: "password",
        src: require("../images/close_eyes.png")
      },
      list:['微信登录' ,'账号登录'],
      aa:false,
      bb:true,
      num: 1,
      isShow:false,
      theShow:true
    }
  },
  created() {
    // 当存在token和用户id同时存在时，跳过登录界面，直接访问主页
    if (window.localStorage.token && window.localStorage.user_id) {
      window.location.href = "index";
      return false;
    };
    // 键盘回车登录
    const that = this
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.LoginIn()
        return false;
      }
    };
  },
  methods: {
    // 获取二维码
    getNum(index){
      this.num = index
      if(index == 0){
        this.aa = true
        this.bb = false
        var obj = new WxLogin({
          self_redirect:false,
          id:"erweima",
          appid: "wx9fc8ed295f1722a6",
          scope: "snsapi_login",
          redirect_uri:encodeURIComponent('http://yocode.com.cn'),
          state: "STATE",
          style: "",
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCiBkaXNwbGF5OiBub25lOwoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgogZGlzcGxheTogbm9uZTsKCn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewoKIGJvcmRlcjogMXB4IHNvbGlkICNFMkUyRTI7CgogbWFyZ2luLXRvcDogMDsKCiB3aWR0aDogMjE1cHg7CgogaGVpZ2h0OiAyMTVweDsKCn0KCi5pbXBvd2VyQm94IC5zdGF0dXN7CgogZGlzcGxheTogbm9uZTsKCiBwYWRkaW5nOiAwOwoKIG1hcmdpbi1sZWZ0OiAyNXB4OwoKfQ=="
        })
      }else{
        this.aa = false
        this.bb = true
      }
    },

    // 截取code
    getUrlParam(name){
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg);  //匹配目标参数
      if (r != null){
        return unescape(r[2]);
      }else{
        return false;
      }
    },

    // 密码显示隐藏
    changeType(){
      this.registration_data.pwdType = this.registration_data.pwdType==='password'?'text':'password';
      this.registration_data.src=this.registration_data.src==require("../images/close_eyes.png")?require("../images/open_eyes.png"):require("../images/close_eyes.png");
    },
    ToCurriculum() {
      // if (this.username === '' || this.password === '') {
      //   alert('账号或密码不能为空');
      // } else {
      //   this.theShow = false,
      //   this.isShow = true
      //   var OBj = new WxLogin({
      //     self_redirect:false,
      //     id:"ErWeiMa",
      //     appid: "wx9fc8ed295f1722a6",
      //     scope: "snsapi_login",
      //     redirect_uri:encodeURIComponent('http://yocode.com.cn'),
      //     state: "STATE",
      //     style: "",
      //     href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCiBkaXNwbGF5OiBub25lOwoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgogZGlzcGxheTogbm9uZTsKCn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewoKIGJvcmRlcjogbm9uZTsKCiB3aWR0aDogMjE1cHg7CgogaGVpZ2h0OiAyMTVweDsKCn0KCi5pbXBvd2VyQm94IC5zdGF0dXN7CgogZGlzcGxheTogbm9uZQoKfQ=="
      //   })
        // axios({
        //   method: 'POST',
        //   url: 'https://back.yocode.com.cn/api/User/login',
        //   dataType: "json",
        //   data: Qs.stringify({
        //     username: this.username,
        //     password: this.password
        //   })
        // }).then((res)=>{
        //   const storage = window.localStorage;
        //   if (res.data.code == 1) {
        //     // 存储用户token、用户id和用户购买班级id
        //     window.localStorage.setItem('token', res.data.data.userinfo.token)
        //     storage.user_id = res.data.data.userinfo.id; //用户ID
        //     storage.class_id = res.data.data.userinfo.classes_id;
        //     if (storage.token) {
        //       // 登录成功加载
        //       const loading = this.$loading({
        //         lock: true,
        //         text: '正在登录······',
        //         customClass: 'create-isLoading',
        //         background: 'rgba(255, 255, 255, 0.3)'
        //       });
        //       setTimeout(() => {
        //         loading.close();
        //         window.location = "index";
        //       }, 2000);
        //     } else {
        //       alert('登录异常');
        //     }
        //   } else if(res.data.code == 0){
        //     alert('您的账号已被锁定，请联系老师')
        //   }
        //   return false;
        // })
      // }
    },
    LoginIn() {
      if (this.username === '' || this.password === '') {
        alert('账号或密码不能为空');
      } else {
        axios({
          method: 'POST',
          url: `${this.$axios.url}/User/login`,
          dataType: "json",
          data: Qs.stringify({
            username: this.username,
            password: this.password
          })
        }).then((res)=>{
          const storage = window.localStorage;
          if (res.data.code == 1) {
            window.localStorage.setItem('token', res.data.data.userinfo.token)
            storage.user_id = res.data.data.userinfo.id; //用户ID
            storage.class_id = res.data.data.userinfo.classes_id;
            if (storage.token) {
              const loading = this.$loading({
                lock: true,
                text: '正在登录······',
                customClass: 'create-isLoading',
                background: 'rgba(255, 255, 255, 0.3)'
              });
              setTimeout(() => {
                loading.close();
                window.location = "index";
              }, 2000);
            } else {
              alert('登录异常');
            }
          } else {
            this.$message.error(res.data.msg);
          }
          return false;
        })
      }
    },
    handleJumpReg(){
      this.$router.push({
        path: "/register",
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loginbox {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background-image: url("../images/loginback.png") !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 1;

  .Login_BOX {
    width: 100%;
    margin-top: 80px;

    .Login_Logo {
      margin-left: 360px;
    }

    .Login_Box {
      overflow: hidden;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;

      .loginlogo {
        width: 614px;
        height: 356px;
        margin: 160px 57px 0 25px;

        image {
          width: 100%;
          height: 100%;
        }

      }

      .Login_box {
        width: 450px;
        height: 450px;
        background: rgba(255, 255, 255, 0.38);
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 30px;
        padding: 25px;
        margin-top: 80px;
        .list-box{
          overflow: hidden;
          list-style:none;
          border-bottom: 1px solid #CCCCCC;
          .liActive{
            float: left;
            font-size: 25px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 36px;
            color: #777777;
            opacity: 1;
            margin-right: 55px;
            cursor: default;
          }
          li:nth-child(0){
            margin-left: 85px;
          }
          li.active{
            border-bottom: 5px solid #FFCB31;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 34px;
            color: #333333;
            opacity: 1;
            padding-bottom: 8px;
          }
        }
        .logininput {
          width: 370px;
          height: 410px;
          background: rgba(255, 255, 255, 1);
          padding: 40px 40px 0 40px;
          border-radius: 30px;

          form {
            width: 100%;

            .input-1,
            .input-2 {
              width: 360px;
              background: rgba(255, 255, 255, 1);
              border: 1px solid rgba(204, 204, 204, 1);
              opacity: 1;
              border-radius: 14px;
              outline: none;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 46px;
              color: #333333;
              display: block;
              margin: 0 auto;
              padding-left: 10px;
            }

            .input-1 {
              margin-top: 47px;
            }

            .input-2 {
              margin-top: 33px;
            }

            .Loginin {
              background: none;
              border: none;
              outline: none;
              cursor: pointer;
            }
          }
        }
        h2{
          text-align: center;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 42px;
          color: #333333;
        }
        .relationWX{
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          margin-top: 6px;
          p{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 16px;
            color: #999999;
            opacity: 1;
            margin-top: 8px;
          }
        }
        .WXsaom{
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 33px;
          color: #333333;
          opacity: 1;
        }
      }
    }
  }
}
iframe {
  display: none;
}
.waring{
  margin-top: 10px;
}
.waring span{
  color: #1980FF;
  cursor: pointer;
}
</style>
