import { render, staticRenderFns } from "./firstSJ.vue?vue&type=template&id=2ce4b649&scoped=true&"
import script from "./firstSJ.vue?vue&type=script&lang=js&"
export * from "./firstSJ.vue?vue&type=script&lang=js&"
import style0 from "./firstSJ.vue?vue&type=style&index=0&id=2ce4b649&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce4b649",
  null
  
)

export default component.exports