<template>
    <div>
        <h3 class="notice-title">
            <span class="title-txt">新闻动态</span><br>
            <span class="title-eng">News Center</span>
        </h3>
        <div class="notice-detail">
            <h2 class="notice-detail-title">河南省教育厅办公室关于举办2021年河南省中小学生科技体育系列竞赛通知</h2>
            <p  class="notice-detail-content">
                各省辖市、济源示范区、省直管县（市）教育局∶<br>
                为了推进我省中小学科技体育活动的深入开展，提高中小学生综合素养，展示我省学校科技体育创新成果，经研究，决定举办 2021年河南省中小学生科技体育系列竞赛。现将竞赛与活动规程印发给你们，请积极组队参加。<br>
                附件：1.2021年河南省中小学科技体育系列竞赛规程<br>
                2.2021年河南省中小学科技体育系列竞赛自愿参赛责任及风险告知书<br>
                3.2021年河南省中小学科技体育系列竞赛报名表<br>

            </p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetailTwo"
    }
</script>

<style scoped>
    .notice-title{
        margin-top: 33px;
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 40px;
    }
    .title-txt{
        color: #1980FF;
        font-size: 30px;
    }
    .title-eng{
        position: relative;
        color: #3F3F3F;
        font-size: 20px;
    }
    .title-eng:before{
        content: "";
        position: absolute;
        left: 20px;
        bottom: -10px;
        display: inline-block;
        width: 80px;
        height: 4px;
        background-color: #1980FF;
    }
    .notice-detail{
        width: 865px;
        margin:40px auto 100px auto;
        min-height: 300px;
    }
    .notice-detail-title{
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color:#3F3F3F;
        font-weight: bold;
    }
    .notice-source{
        margin-top: 20px;
        color:#3F3F3F;
    }
    .notice-detail-content{
        margin-top: 30px;

        line-height: 30px;
        font-size: 14px;
        color:#000;
    }
</style>
