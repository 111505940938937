<template>
<div id="app">
  <div v-if="$route.meta.isShow">
    <!-- 用户端官网首页导航 -->
    <el-menu style="height:80px;" :default-active="$route.path" class="el-menu-demo" mode="horizontal" background-color="#FFFFFF" text-color="#333333" router>
      <div style="float:left;margin-top:23px;margin-left:300px;border:none;outline:none;cursor:pointer;" class="index_header" @click="ToHome">
        <img style="width:275px;height:34px" src="../src/images/yocode.png" alt="" draggable="false">
      </div>
      <div style="float:right;width: 140px;height: 60px;background: #F9CB00;opacity: 1;border-radius: 30px;text-align:center;margin-top:10px;margin-right:230px;border:none;outline:none;cursor:pointer;" class="index_button" @click="ToLogin"><span style="font-size: 18px;font-family: PingFang SC;font-weight: 500;line-height: 60px;color: #FFFFFF;opacity: 1;">登录学习系统</span></div>
        <el-menu-item style="height:80px;font-size:18px;line-height:80px;float:right;margin-right:50px" class="index_text" index="/jingsai">进入竞赛</el-menu-item>
      <el-menu-item style="height:80px;font-size:18px;line-height:80px;float:right;margin-right:50px" class="index_text" index="/process">上课流程</el-menu-item>
      <el-menu-item style="height:80px;font-size:18px;line-height:80px;float:right;" index="/teachingmode">教学模式</el-menu-item>
      <el-menu-item style="height:80px;font-size:18px;line-height:80px;float:right;" index="/curriculum">课程体系</el-menu-item>
      <el-menu-item style="text-align:center;width:100px;height:80px;font-size:18px;line-height:80px;float:right;" index="/">首页</el-menu-item>
    </el-menu>
  </div>
  <div>
    <router-view />
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex:'1'
    }
  },
  methods: {
    ToHome(){
      window.location.href = "/home"
    },
    ToLogin(){
      window.open("about:blank").location.href = "/"
    }
  },
}
</script>

<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
p,
ul,
li {
  margin: 0;
}

a {
  text-decoration: none;
}

body {
  min-width: 1270px;
  background: url("./images/indexback.png") no-repeat;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 6px solid #F9CB00 !important;
}
@media screen and(max-width:1600px) {
  /*.index_header{*/
    /*margin-left: 140px !important;*/
  /*}*/
  .index_button{
    margin-right:120px !important;
  }
  .index_text{
    margin-right:20px !important;
  }
  body{
    margin-bottom: 60px !important;
  }
}
</style>
