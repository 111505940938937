<template>
    <div>
      <h3 class="notice-title">
        <span class="title-txt">新闻动态</span><br>
        <span class="title-eng">News Center</span>
      </h3>
      <div class="notice-detail">
        <img src="@/assets/images/notice-img.png" alt="" width="865" height="280">
        <h2 class="notice-detail-title">青少年编程能力等级考试哪家强？三大主流编程等考深度对比</h2>
        <div class="notice-source">
          来源：游研世界 作者：YO小程 2021-07-27
        </div>
        <p  class="notice-detail-content">
          2018年，国务院印发了《新一代人工智能发展规划》，明确了我国新一代人工智能发展的战略目标：到2020年，人工智能产业成为新的重要经济增长点，人工智能技术应用成为改善民生的新途径；到2025年，人工智能将成为我国产业升级和经济转型的主要动力。
        </p>
        <p  class="notice-detail-content">
          职业教育与普通教育是两种不同教育类型，具有同等重要地位。改革开放以来，职业教育为我国经济社会发展提供了有力的人才和智力支撑，现代职业教育体系框架全面建成，服务经济社会发展能力和社会吸引力不断增强，具备了基本实现现代化的诸多有利条件和良好工作基础。随着我国进入新的发展阶段，产业升级和经济结构调整不断加快，各行各业对技术技能人才的需求越来越紧迫，职业教育重要地位和作用越来越凸显。
        </p>
        <p  class="notice-detail-content">
          习近平总书记对职业教育工作作出重要指示强调，在全面建设社会主义现代化国家新征程中，职业教育前途广阔、大有可为。
        </p>
      </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetail",
      data(){
          return {
             paramsId:""
          }
      },
      created() {
        this.paramsId=this.$route.params.id;
        if(this.paramsId===0){
          this.$router.push({ path: '/jingsai/noticeDetailOne'})
        }else if(this.paramsId===1){
          this.$router.push({ path: '/jingsai/noticeDetailTwo'})
        }else if(this.paramsId===2){
          this.$router.push({ path: '/jingsai/noticeDetailThree'})
        }else if(this.paramsId===3){
          this.$router.push({ path: '/jingsai/noticeDetailFour'})
        }else if(this.paramsId===4){
          this.$router.push({ path: '/jingsai/noticeDetailFive'})
        }else if(this.paramsId===5){
          this.$router.push({ path: '/jingsai/noticeDetailSix'})
        }else{
          this.$router.push({ path: '/jingsai/notice'})
        }
      }
    }
</script>

<style scoped>
  .notice-title{
    margin-top: 33px;
    text-align: center;
    border-bottom: 1px solid #707070;
    padding-bottom: 40px;
  }
  .title-txt{
    color: #1980FF;
    font-size: 30px;
  }
  .title-eng{
    position: relative;
    color: #3F3F3F;
    font-size: 20px;
  }
  .title-eng:before{
    content: "";
    position: absolute;
    left: 20px;
    bottom: -10px;
    display: inline-block;
    width: 80px;
    height: 4px;
    background-color: #1980FF;
  }
  .notice-detail{
    width: 865px;
    margin:40px auto 100px auto;
  }
  .notice-detail-title{
    margin-top: 50px;
    text-align: center;
  font-size: 20px;
    color:#3F3F3F;
    font-weight: bold;
  }
  .notice-source{
    margin-top: 20px;
    color:#3F3F3F;
  }
  .notice-detail-content{
    text-indent: 28px;
    margin-top: 30px;

    line-height: 30px;
    font-size: 14px;
    color:#000;
  }
</style>
