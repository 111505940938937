<template>
<div>
  <div class="zpsb-wrap">
    <p class="txt-info">为了推进我省中小学科技体育活动的深入开展，提高中小学生综合素养，展示我省学校科技体育创新成果，构建中小学生科技体育活动优质平台，有效促进结合我省学校学科教育和STEM教育以及 "一校一品""一校多品"特色学校建设和学校科技体育活动的普及。</p>
    <p class="txt-info"> 民族研学叠叠乐是由56个身着各民族服饰的积木小人组成，体现了56个民族大团结的寓意。学生通过竞赛的形式，掌握我国多民族丰富的服饰文化。</p>
    <div class="zpsb-info">
      <span class="sb-btn" @click="handleWorksReport">作品申报</span><br>
      <a  class="sb-link" @click="handleOnload">点击附件下载</a><br>
      <span class="sb-time">申报截止时间：2021年10月29日23:59:59</span>
    </div>
  </div>
  <div class="jsdx-wrap">
      <h3 class="sx-set-title">竞赛对象</h3>
    <div class="jsdx-block">
      <div class="jsdx-block-top">
        <div class="jsdx-block-left">
          <img src="@/assets/images/map.png" alt="">
        </div>
        <div class="jsdx-block-right">
          各省辖市、济源示范区、直管试点县（市） 中小学有正式学籍在校学生（含职专）
        </div>
      </div>
      <div class="jsdx-block-warning">
        *选手需提前自行购买“民族研学叠叠乐”携带入场
      </div>
    </div>
  </div>
  <div class="jsnr-wrap">
    <div class="jsnr-content">
      <h3 class="sx-set-title blue-title">竞赛内容</h3>
      <p class="desc"><span>在规定时间内，选手按照竞赛要求完成赛场提供的“民族研学叠叠乐”的叠放比赛</span></p>
      <div class="jsnr-block">
        <div class="jsnr-block-left">
          <p class="jsnr-block-left-item"><span>把总数56个民族人物叠在一起， 托举平举任意选择</span></p>
          <p class="jsnr-block-left-item"><span>最下层不能超过5个人物</span></p>
          <p class="jsnr-block-left-item"><span>以最终叠放数目 最多者取胜</span></p>
        </div>
        <div class="jsnr-block-mid">
          <img src="@/assets/images/die.png" alt="">
        </div>
        <div class="jsnr-block-right">
          <p class="jsnr-block-left-item"><span>数目相同情况下 叠放高度最高者取胜</span></p>
          <p class="jsnr-block-left-item"><span>数目、高度均相同的情况下 叠放难度最大者获胜</span></p>
          <p class="jsnr-block-left-item"><span>每名参赛者均有三次机会， 比赛时间10分钟</span></p>
        </div>
      </div>
    </div>
  </div>
  <div class="zpyq-wrap">
    <h3 class="sx-set-title">注意事项</h3>
    <div class="zpyq-txt-desc">
      <p class="desc-item">
        （1）参赛人员必须统一佩戴由竞赛组委会签发的相关证件，着装整齐
      </p>
      <p class="desc-item">
        （2）参赛选手按规定时间到达指定地点，凭黪赛证和身份证进入赛场,并随机抽取工位号，选手迟到30分钟取消竞赛资格
      </p>
      <p class="desc-item">
        （3）各队领队和指导教师竞赛中不得进入赛场内
      </p>
      <p class="desc-item">
        （4）裁判组在赛前15分钟，对参赛选手的证件进行检查及进行竞赛相关事项教育
      </p>
      <p class="desc-item">
        （5）参赛选手务必按照竞赛规程进行操作。不得携带通讯工具和其它未经允许的资料、物品进入竞赛场地,实操竞赛开赛后30分钟内不得中途退场。如出现较严重的违规、违纪、舞弊等现象，经裁判组裁定将当即取消竞赛成绩
      </p>
      <p class="desc-item">
        （6）竞赛过程中，选手若需休息、饮水或去洗手间，一律计算在竞赛时间内
      </p>
    </div>
  </div>
</div>
</template>

<script>
    export default {
        name: "secondSJ",
      methods:{
        handleWorksReport(){
          //选择判断是否登录，只有登录才能作品申报
          if(window.localStorage.token){
            this.$router.push({ path: '/jingsai/worksReport', query: { param: 2 }})
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
          }
        },
        handleOnload(){
          if(window.localStorage.token){
            window.open("");
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
            return false;
          }
        }
      }
    }
</script>

<style scoped>
  .zpsb-wrap,
  .jsnr-content{
    width:1200px;
    margin:0 auto;
  }
  .txt-info{
    margin-top: 39px;
    text-indent: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
  }
  .zpsb-info{
    margin-top:24px;
    text-align: center;
    line-height: 50px;
  }
  .sb-btn{
    display: inline-block;
    font-size: 30px;
    margin-bottom: 8px;
    width:300px;
    height:80px;
    line-height: 80px;
    text-align: center;
    color:#fff;
    background-color: #ff7e24;
    border-radius: 10px;
    cursor: pointer;
  }
  .sb-link{
    font-size: 20px;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }
  .sb-link:link{
    border-bottom:1px solid #000;
  }
  .sb-time{
    font-size: 20px;
  }
  .jsdx-wrap{
    margin-top: 100px;
  }
  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:100px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .jsdx-block{
    width: 1200px;
    margin: 39px auto 20px auto;
  }
  .jsdx-block-top{
    display: flex;
  }
  .jsdx-block-left{
  margin-right: 58px;
  }
  .jsdx-block-right{
    padding-top:14px;
    padding-left:28px;
    margin-top: 109px;
    width: 630px;
    height: 106px;
    font-size: 30px;
    color: #fff;
    background-color: #1980FF;
  }
  .jsdx-block-warning{
    text-align: center;
    margin-top: 38px;
    color:#1980FF;
    font-size: 20px;
  }
  .blue-title{
    height:180px;
    line-height: 180px;
   color:#1980FF;
    font-size: 34px;
    border-bottom: 1px solid #000;
  }
  .jsnr-wrap{
    width: 1920px;
    height: 780px;
    background-color: #F0F8FF;
  }
  .jsnr-wrap .desc{
    position: relative;
    top: -50px;
    text-align: center;
  color:#000000;
    font-size: 20px;
  }
  .jsnr-block{
   display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .jsnr-block-left{

  }
  .jsnr-block-left-item{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    width: 360px;
    height:120px;
    border:1px solid #1980FF;
    border-radius: 10px;
    font-size: 20px;
    color: #000;
    line-height: 28px;
  }
  .jsnr-block-left-item span{
    text-align: center;
    display: inline-block;
    width:250px
  }
  .jsnr-block-mid{
    margin-top: 50px;
  }
  .jsnr-block-right{

  }
  .zpyq-wrap{
    background: url("../../../assets/images/zp-bg.png") no-repeat;
    margin-top: 80px;
  }
  .zpyq-wrap  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:100px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .zpyq-txt-desc{
    width:1200px;
    margin:60px auto;
    color:#000;
    font-size: 20px;
  }
  .desc-item{
    line-height: 30px;
    margin-bottom:30px;
  }
</style>
