<template>
    <div>
        <h3 class="notice-title">
            <span class="title-txt">新闻动态</span><br>
            <span class="title-eng">News Center</span>
        </h3>
        <div class="notice-detail">

            <h2 class="notice-detail-title">2016年河南省中小学生科技体育竞赛暨科技体育社团展示活动在郑州市经开区实验中学、经开区六一小学举行</h2>
            <p  class="notice-detail-content">
                2016年11月26日，由河南省教育厅主办，河南省学生体育总会科技体育协会、郑州市经开区教体局和郑州师范学院承办的“2016年河南省中小学科技体育竞赛暨科技体育社团展示活动”在郑州市经开区实验中学、经开区六一小学拉开帷幕。本次活动以“科技启迪智慧、体育健康人生”为主题，共设“科技体育竞赛”和“中小学科技体育社团展示评比活动”两个板块。在为期3天的活动中，来自郑州市、洛阳市、开封市、鹤壁市、新乡市、焦作市、安阳市、周口市、漯河市等10多个地、市（区）的82支代表队的1600名选手，将参加“校园纸飞机模型”、“校园室内遥控直升机”、“校园创意科教模型”、“校园建筑模型”、 “校园模拟飞行”、“校园航海模型”等6个大项18个小项的比赛，在竞赛中展开紧张激烈的角逐。社团展示活动内容丰富，形式多样，将成为河南省中小学展示科技体育教育、教学成果和航模社团学生风采的良好平台。
            </p>
            <img src="@/assets/images/6-1.jpg" alt="">
            <img src="@/assets/images/6-2.jpg" alt="">
            <img src="@/assets/images/6-3.jpg" alt="">
            <img src="@/assets/images/6-4.jpg" alt="">
            <img src="@/assets/images/6-5.jpg" alt="">
            <img src="@/assets/images/6-6.jpg" alt="">
            <img src="@/assets/images/6-7.jpg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetailSix"
    }
</script>

<style scoped>
    .notice-title{
        margin-top: 33px;
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 40px;
    }
    .title-txt{
        color: #1980FF;
        font-size: 30px;
    }
    .title-eng{
        position: relative;
        color: #3F3F3F;
        font-size: 20px;
    }
    .title-eng:before{
        content: "";
        position: absolute;
        left: 20px;
        bottom: -10px;
        display: inline-block;
        width: 80px;
        height: 4px;
        background-color: #1980FF;
    }
    .notice-detail{
        width: 865px;
        margin:40px auto 100px auto;
    }
    .notice-detail-title{
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color:#3F3F3F;
        font-weight: bold;
    }
    .notice-source{
        margin-top: 20px;
        color:#3F3F3F;
    }
    .notice-detail-content{
        text-indent: 28px;
        margin-top: 30px;

        line-height: 30px;
        font-size: 14px;
        color:#000;
    }
</style>
