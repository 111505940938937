<template>
    <div>
      <h3 class="notice-title">
        <span class="title-txt">通知公告</span><br>
        <span class="title-eng">Notice</span>
      </h3>
      <div class="notice-wrap">
        <div class="notice-item" v-for="(item,index) in noticeData" :key="index">
          <div class="date-left">
            <img :src="require('@/assets/images/notice-img'+index+'.jpg')"  alt="" width="220" height="130">
          </div>
          <div class="notice-txt-right">
            <p class="notice-content">
              <router-link  :to="{ name:'noticeDetail', params:{id: index }}"> {{item.title}}</router-link>
            </p>
            <div class="detail-link" >{{item.dateTime}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "index",
      data(){
          return {
            noticeData:[
              {
                dateTime:"2021-05-27",
                title:"2021年河南省中小学生科技体育系列竞赛规程",
                hrefLink:''
              },{
                dateTime:"2021-05-27",
                title:"河南省教育厅办公室关于举办2021年河南省中小学生科技体育系列竞赛通知",
                hrefLink:''
              },{
                dateTime:"2021-05-24",
                title:"2019年河南省中小学生科技体育竞赛暨科技体育社团展评活动举行",
                hrefLink:''
              },{
                dateTime:"2021-05-24",
                title:"2018年河南省中小学科技体育竞赛圆满成功！",
                hrefLink:''
              },{
                dateTime:"2021-05-24",
                title:"2017年河南省中小学生科技体育竞赛暨科技体育社团展示活动圆满成功",
                hrefLink:''
              },{
                dateTime:"2021-05-24",
                title:"2016年河南省中小学生科技体育竞赛暨科技体育社团展示活动在郑州市经开区实验中学、经开区六一小学举行",
                hrefLink:''
              }
            ]
          }
      }
    }
</script>

<style scoped>
.notice-title{
  margin-top: 33px;
 text-align: center;
}
  .title-txt{
  color: #1980FF;
    font-size: 30px;
  }
  .title-eng{
    position: relative;
   color: #3F3F3F;
    font-size: 20px;
  }
.title-eng:before{
  content: "";
  position: absolute;
  left: -10px;
  bottom: -10px;
  display: inline-block;
  width: 80px;
  height: 4px;
  background-color: #1980FF;
}
  .notice-wrap{
    width: 1000px;
    margin:98px auto 98px auto;
  }
  .notice-item{
   display: flex;
    padding-bottom: 20px;
    margin-bottom:20px;
    border-bottom: 1px solid #707070;
  }
  .date-left{
    margin-right: 20px;
    text-align: center;
    width: 220px;
    height: 130px;
  }
.date-left:hover .date-left-txt-ri,
.date-left:hover .date-left-txt{
  color: #fff;
}
  .date-left-txt-ri{
    margin-top: 14px;
    display: block;
    font-size: 30px;
    color:#3F3F3F;
  }
  .date-left-txt{
    color:#3F3F3F;
  }
  .notice-txt-right{
    width: 757px;
  }
  .notice-txt-title{
    width: 337px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;

  }
  .notice-content{
    display: inline-block;
    line-height: 30px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

  }
.notice-content a{
  color: #3F3F3F;
  font-size: 20px;
}
  .detail-link{
    color: #3F3F3F;
    font-size: 14px;
    margin-top:82px;
  }
</style>
