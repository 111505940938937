import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    classID:window.localStorage.getItem('classID'),
    name: window.localStorage.getItem('name'),
    chapter: window.localStorage.getItem('chapter'),
    avatar: window.localStorage.getItem('avatar'),
    nickname: window.localStorage.getItem('nickname'),
    username: window.localStorage.getItem('username'),
    sex: window.localStorage.getItem('sex'),
    year: window.localStorage.getItem('year'),
    month: window.localStorage.getItem('month'),
    day: window.localStorage.getItem('day'),
    age: window.localStorage.getItem('age'),
    chapterID: '',
    startTime: window.localStorage.getItem('startTime'),
    taskId: '',
    aIndex:'',
    videoUrl: '',
    Active: window.localStorage.getItem('Active'),
    IActive: window.localStorage.getItem('IActive'),
    getTime:window.localStorage.getItem('getTime'),
    curriculumID:'',
    Pre:window.localStorage.getItem('Pre'),
    bio:window.localStorage.getItem('bio'),
    gender:window.localStorage.getItem('gender')
  },
  mutations: {
    changeclassID(state, val){
      state.classID = val
    },
    changename(state, val){
      state.name = val
    },
    changechapter(state, val){
      state.chapter = val
    },
    changeavatar(state, val){
      state.avatar = val
    },
    changenickname(state, val){
      state.nickname = val
    },
    changeusername(state, val){
      state.username = val
    },
    changesex(state, val){
      state.sex = val
    },
    changeyear(state, val){
      state.year = val
    },
    changemonth(state, val){
      state.month = val
    },
    changeday(state, val){
      state.day = val
    },
    changeage(state, val){
      state.age = val
    },
    changechapterID(state, val){
      state.chapterID = val
    },
    changestartTime(state, val){
      state.startTime = val
    },
    changeaIndex(state, val){
      state.aIndex = val
    },
    changevideoUrl(state, val){
      state.videoUrl = val
    },
    changetaskId(state, val){
      state.taskId = val
    },
    changeActive(state, val){
      state.Active = val
    },
    changeIActive(state, val){
      state.IActive = val
    },
    changegetTime(state, val){
      state.getTime = val
    },
    changecurriculumID(state, val){
      state.curriculumID = val
    },
    changePre(state, val){
      state.Pre = val
    },
    changebio(state, val){
      state.bio = val
    },
    changegender(state, val){
      state.gender = val
    }
  },
  actions: {
  },
  modules: {
  }
})

// 点击事件里,修改name
// this.$store.commit('changename', '张三')
// window.localStorage.setItem('name', '张三')


// 获取name
// this.$store.state.name