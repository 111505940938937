<template>
    <div>
        <h3 class="notice-title">
            <span class="title-txt">新闻动态</span><br>
            <span class="title-eng">News Center</span>
        </h3>
        <div class="notice-detail">

            <h2 class="notice-detail-title">2018年河南省中小学科技体育竞赛圆满成功！</h2>
            <p  class="notice-detail-content">
                2018年河南省中小学科技体育竞赛，于11月23-25日在郑州市第十九中学如期举办。全省150所学校3100名选手参加了本次竞赛。河南省教育厅体卫艺处副处长高翔，郑州市教育局副调研员，郑州市教育局体卫艺处处长高百中、河南省学生体育总会副秘书长、科技体育协会秘书长吕刚，科技体育协会副秘书长郭常有，郑州市教育局体卫艺处体育专干仝永东，郑州市第十九中学校长梁寅峰、副校长李伏庆等领导出席了开幕仪式。<br>
            </p>
            <p  class="notice-detail-content">
                本次竞赛有序、紧张、热烈、奋进。是河南省学校科技体育的顶级赛事，是历年来规模最大，参与人数和学校最多的一次盛会。近年来，在教育厅的正确指引下，以及各级领导、各地市教育行政主管部门的关心支持下，科技体育活动在我省得到了长足的发展，并以崭新的面貌，不断发挥自身特点和教育功能，与时俱进。不断打造河南科技体育活动的饕餮盛宴。<br>
            </p>
            <p  class="notice-detail-content">
                本次竞赛共设置航空模型、航海模型、建筑模型、车辆模型、模拟飞行、无线测向六大板块，32个小项的竞赛。目的是为了推动我省中小学校科技体育活动深入开展和普及，结合我省“一校一品”“一校多品”特色学校的建设，打造学校普遍参与，学生普遍受益，社会普遍关注的良好竞赛和展示的平台。发挥科技体育对中小学生综合素质提高的良好推动作用。

            </p>
            <img src="@/assets/images/4-1.jpg" alt="">
            <img src="@/assets/images/4-2.jpg" alt="">
            <img src="@/assets/images/4-3.jpg" alt="">
            <img src="@/assets/images/4-4.jpg" alt="">
            <img src="@/assets/images/4-5.jpg" alt="">
            <img src="@/assets/images/4-6.jpg" alt="">
            <img src="@/assets/images/4-7.jpg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetailFour"
    }
</script>

<style scoped>
    .notice-title{
        margin-top: 33px;
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 40px;
    }
    .title-txt{
        color: #1980FF;
        font-size: 30px;
    }
    .title-eng{
        position: relative;
        color: #3F3F3F;
        font-size: 20px;
    }
    .title-eng:before{
        content: "";
        position: absolute;
        left: 20px;
        bottom: -10px;
        display: inline-block;
        width: 80px;
        height: 4px;
        background-color: #1980FF;
    }
    .notice-detail{
        width: 865px;
        margin:40px auto 100px auto;
    }
    .notice-detail-title{
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color:#3F3F3F;
        font-weight: bold;
    }
    .notice-source{
        margin-top: 20px;
        color:#3F3F3F;
    }
    .notice-detail-content{
        margin-top: 30px;

        line-height: 30px;
        font-size: 14px;
        color:#000;
    }
</style>
