<template>
    <div class="works-report-wrap">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="作品申报" name="first" style="width: 600px">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="赛项：" prop="comptype">
              <el-input v-model="ruleForm.comptype" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="username">
              <el-input v-model="ruleForm.username"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="gender">
              <el-radio-group v-model="ruleForm.gender">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="联系电话：" prop="mobile">
              <el-input v-model="ruleForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="证件号码：" prop="idcard">
              <el-input v-model="ruleForm.idcard"></el-input>
            </el-form-item>
            <el-form-item label="学校名称：" prop="schoolname">
              <el-input v-model="ruleForm.schoolname"></el-input>
              <!--<el-select v-model="ruleForm.schoolcode" filterable >-->
                <!--<el-option-->
                        <!--v-for="item in schoolcodeOptions"-->
                        <!--:key="item.code"-->
                        <!--:label="item.name"-->
                        <!--:value="item.code"-->
                <!--&gt;</el-option>-->
              <!--</el-select>-->
            </el-form-item>
            <el-form-item label="指导老师：" prop="teachername">
              <el-input v-model="ruleForm.teachername"></el-input>
            </el-form-item>
            <el-form-item label="组 别：" prop="compgroupcode">
              <el-select v-model="ruleForm.compgroupcode" >
                <el-option
                        v-for="item in compgroupOptions"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="作品类型：" prop="compworktypecode">
              <el-select v-model="ruleForm.compworktypecode" >
                <el-option
                        v-for="item in compworktypeOptions"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model="ruleForm.remark" maxlength="200"
                        show-word-limit rows="6"></el-input>
            </el-form-item>
            <el-form-item label="上传附件：" prop="filepath">
              <el-upload
                class="upload-demo"
                :action="uploadAction"
                accept=".rar,.zip"
                :data="{isdelattachlog:'Y'}"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeUpload"
                :on-success="successUpload"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传.rar/.zip文件，且不超过150MB</div>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="申报记录" name="second">
          <el-table
            :data="tableData"
            style="width: 100%; margin-bottom: 60px;"
          >
            <el-table-column
              prop="comptype"
              label="赛项"
              width="180">
            </el-table-column>
            <el-table-column
              prop="compworktypename"
              label="作品类别"
              width="180">
            </el-table-column>
            <el-table-column
              prop="username"
              label="申报人">
            </el-table-column>
            <el-table-column
                    prop="idcard"
                    label="证件号码">
            </el-table-column>
            <el-table-column
                    prop="schoolname"
                    label="学校">
            </el-table-column>
            <el-table-column
                    prop="teachername"
                    label="指导老师">
            </el-table-column>
            <el-table-column
                    prop="compgroupname"
                    label="组别">
            </el-table-column>
            <el-table-column
              prop="createtime"
              label="申报时间">
              <template slot-scope="scope">
               {{getTime(scope.row.createtime)}}
              </template>
            </el-table-column>
            <el-table-column
              prop="filename"
              label="申报附件">
              <template slot-scope="scope">
                <a type="text" size="small" :href="scope.row.filepath">{{scope.row.filename}}</a>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
</template>

<script>
  import moment from 'moment';
  import {addWorksReprot,getWorksReprot,getWorksReprotLastest,getCategory} from "@/api/worksReport"
    export default {
        name: "index",
      data(){
          return {
            activeName: "first",
            ruleForm: {
              comptype: "",
              username: "",
              mobile: "",
              gender: "1",
              idcard: "",
                schoolname: "",
              teachername: "",
              compgroupcode: "",
              compworktypecode: "",
              remark: "",
              filepath: ""
            },
            rules: {
              username: [
                {required: true, message: '姓名不能为空', trigger: 'blur'},
              ],
              gender: [
                {required: true, message: '性别不能为空', trigger: 'blur'},
              ],
              mobile: [
                {required: true, message: '电话不能为空', trigger: 'blur'},
              ],
              idcard: [
                {required: true, message: '身份证号不能为空', trigger: 'blur'},
              ],
                schoolname: [
                {required: true, message: '学校不能为空', trigger: 'blur'},
              ],
              teachername: [
                {required: true, message: '指导老师不能为空', trigger: 'blur'},
              ],
              compgroupcode: [
                {required: true, message: '组别不能为空', trigger: 'blur'},
              ],
              compworktypecode: [
                {required: true, message: '作品类型不能为空', trigger: 'blur'},
              ],
            },
            schoolcodeOptions:[],
            compgroupOptions:[],
            compworktypeOptions:[],
            uploadAction:`${this.$axios.url}/file/upload`,
            fileList: [],
            tableData: [],
          }},
      created(){
          //获取作品申请中的赛项
        if(this.$route.query.param){
          let paramValue=this.$route.query.param;
          switch (paramValue) {
            case "1":
              this.ruleForm.comptype="水陆两栖车拼装竞速赛";
              break;
            case "2":
              this.ruleForm.comptype="民族大联欢叠叠高挑战赛";
              break;
            case "3":
              this.ruleForm.comptype="深海救援挑战赛";
              break;
            case "4":
              this.ruleForm.comptype="智慧城市造物智能设计赛";
              break;
            case "5":
              this.ruleForm.comptype="童心向党心电子创意设计赛";
              break;
            default :
              this.ruleForm.comptype="童心向党心电子创意设计赛";
              break;
          }
        }
        //获取学校
        this.getSchoolData();
        //获取组别
        this.getCompGroupData();
        //获取作品类型
        this.getCompWorkTypeData();
        //根据赛项获得用户非第一次申报信息
        this.getLastestData();

      },
      methods: {
        getSchoolData(){
          let params="school";
          getCategory(params).then((res)=>{
            if(res.data.code===1){
               this.schoolcodeOptions=res.data.data;
            }else{
              this.$message.error(res.data.msg);
            }
          })
        },
        getCompGroupData(){
          let params="compgroup";
          getCategory(params).then((res)=>{
            if(res.data.code===1){
              this.compgroupOptions=res.data.data;
            }else{
              this.$message.error(res.data.msg);
            }
          })
        },
        getCompWorkTypeData(){
          let params="compworktype";
          getCategory(params).then((res)=>{
            if(res.data.code===1){
              this.compworktypeOptions=res.data.data;
            }else{
              this.$message.error(res.data.msg);
            }
          })
        },
        getReportData(){
          let params={
            user_id:localStorage.getItem('user_id'),
            comptype:this.ruleForm.comptype
          };
          getWorksReprot(params).then((res)=>{
            if(res.data.code===1){
              this.tableData=res.data.data;
            }else{
              this.$message.error(res.data.msg);
            }
          })
        },
        getLastestData(){
          let params={
            user_id:localStorage.getItem('user_id'),
            comptype:this.ruleForm.comptype
          };
          getWorksReprotLastest(params).then((res)=>{
            if(res.data.code===1){
              let resultData=res.data.data;
              this.ruleForm.username=resultData.username;
              this.ruleForm.gender=resultData.gender;
              this.ruleForm.mobile=resultData.mobile;
              this.ruleForm.idcard=resultData.idcard;
              this.ruleForm.schoolname=resultData.schoolname;
              this.ruleForm.teachername=resultData.teachername;
            }
          })
        },
        handleRemove(file, fileList) {
          console.log(file, fileList);
        },
        handlePreview(file) {
          console.log(file);
        },
        handleExceed(files, fileList) {
          this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
          let status=true;
          if(status && file.status==="success"){
            status=this.$confirm(`确定移除 ${ file.name }？`);
            if(status){
              this.fileList=[];
            }
          }

          return status;
        },
        beforeUpload(file){
          const imgType=file.name.substring(file.name.lastIndexOf('.')+1);
          const isLt150MB = file.size / 1024 /1024  < 150;
          if (imgType!== 'zip' && imgType!== 'rar') {
            this.$message.error("上传文件只能是rar和zip格式!");
            return false;
          }
          if (!isLt150MB) {
            this.$message.error("上传图片大小不能超过 150MB!");
            return false;
          }
        },
        successUpload(response, file, fileList){
         if(response.msg==="上传成功"){
           this.fileList.push({
             name:file.name,
             url:response.code.fullurl
           })
         }
        },
        submitForm(formName) {
          if(this.fileList.length===0){
            this.$message.error("至少选择一个文件上传");
            return false;
          }
          this.$refs[formName].validate((valid) => {
            if (valid) {
              let userId={
                user_id:localStorage.getItem('user_id')
              };
              let uploadFile={
                filepath:this.fileList[0].url,
                filename:this.fileList[0].name
              };
              let paramsValues={...this.ruleForm,...userId,...uploadFile};
              addWorksReprot(paramsValues).then((res)=>{
               if(res.data.code===1){
                 this.$message({
                   message: res.data.msg,
                   type: 'success'
                 });
                 this.$refs[formName].resetFields();
                 this.fileList=[];
               }else{
                 this.$message.error( res.data.msg);
                 return false;
               }
              })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
        handleTabClick(activeTab){
          if(activeTab.name==="second"){
            //得到申报记录数据
            this.getReportData();
          }
        },
        getTime(dataTime){
          if(dataTime){
            return moment.unix(dataTime).format("YYYY-MM-DD");
          }

        }
      }
    }
</script>

<style scoped>
 .works-report-wrap{
   width: 1200px;
   margin: 0 auto;
 }
  /deep/ .el-tabs__item{
    margin-top: 20px;
    font-size: 18px;
  }
  /deep/ .el-tabs__nav-wrap::after{
    background:none;
  }
  /deep/ .el-form-item__label{
    font-size: 18px;
  }
  /deep/ .el-button--primary{
    margin-top:0;
  }
  /deep/ .el-tabs__header{
    margin-bottom: 30px;
  }
  /deep/ .el-table thead{
    color: #000;
  }
  /deep/ .el-table th{
    background-color: #F4F4F5;
  }
  /deep/ .el-table th.is-leaf{
    border-bottom: none;
  }
  /deep/ .el-pagination{
    text-align: right;
    font-weight: normal;
  }
  .pagination{
    margin:20px 0 20px 0;
  }
  /deep/ .el-select{
    width: 100%;
  }
</style>
