<template>
    <div>
        <h3 class="notice-title">
            <span class="title-txt">新闻动态</span><br>
            <span class="title-eng">News Center</span>
        </h3>
        <div class="notice-detail">

            <h2 class="notice-detail-title">2017年河南省中小学生科技体育竞赛暨科技体育社团展示活动圆满成功</h2>
            <p  class="notice-detail-content">
                12月9日，2017年河南省中小学生科技体育竞赛暨科技体育社团展示活动在鹤壁市淇滨小学举行。 来自全省各地的中小学生参加了此次活动。<br>
                本次活动以“科技启迪智慧、体育健康人生”为主题，共设科技体育竞赛和社团展示评比活动两大板块。其中竞赛项目设立了校园纸飞机模型、校园室内遥控直升机、校园创意科教模型、校园建筑模型、校园模拟飞行、校园航海模型等比赛，展示活动内容丰富，形式多样，成为中小学校展现科技体育成果和风采的良好平台。<br>
                此次活动由河南省教育厅主办，省学生体育总会科技体育协会、鹤壁市教育局协办，鹤壁市淇滨小学承办。<br>
                科技体育竞赛能很好地把科技创新与体育相融合。是广大中小学生展示科技体育成果的良好平台。开展科技体育竞赛的根本宗旨就是要培养中小学生的创新精神和实践能力。促进和激发崇尚科学，勇于探索的精神，培养立志成才，报效祖国的民族责任感。提高中小学生思维能力、创造能力和实践能力，促进科学素质和身体素质的全面提高。

            </p>
            <img src="@/assets/images/5-1.jpg" alt="">
            <img src="@/assets/images/5-2.jpg" alt="">
            <img src="@/assets/images/5-3.jpg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetailFive"
    }
</script>

<style scoped>
    .notice-title{
        margin-top: 33px;
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 40px;
    }
    .title-txt{
        color: #1980FF;
        font-size: 30px;
    }
    .title-eng{
        position: relative;
        color: #3F3F3F;
        font-size: 20px;
    }
    .title-eng:before{
        content: "";
        position: absolute;
        left: 20px;
        bottom: -10px;
        display: inline-block;
        width: 80px;
        height: 4px;
        background-color: #1980FF;
    }
    .notice-detail{
        width: 865px;
        margin:40px auto 100px auto;
    }
    .notice-detail-title{
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color:#3F3F3F;
        font-weight: bold;
    }
    .notice-source{
        margin-top: 20px;
        color:#3F3F3F;
    }
    .notice-detail-content{
        text-indent: 28px;
        margin-top: 30px;

        line-height: 30px;
        font-size: 14px;
        color:#000;
    }
</style>
