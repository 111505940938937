<template>
   <div class="introduce-wrap">
     <el-tabs v-model="activeName">
       <el-tab-pane label="“童心向党心”电子创意设计赛" name="five">
         <fifthSJ></fifthSJ>
       </el-tab-pane>
       <el-tab-pane label="“智慧城市”造物智能设计赛" name="fourth">
         <fourthSJ></fourthSJ>
       </el-tab-pane>
       <el-tab-pane label="“深海救援”挑战赛" name="third">
         <thirdSJ></thirdSJ>
       </el-tab-pane>
       <el-tab-pane label="“民族大联欢”叠叠高挑战赛" name="second">
         <secondSJ></secondSJ>
       </el-tab-pane>
       <el-tab-pane label="水陆两栖车拼装竞速赛" name="first">
           <firstSJ></firstSJ>
       </el-tab-pane>
     </el-tabs>

   </div>
</template>

<script>
  import firstSJ from "./firstSJ";
  import secondSJ from "./secondSJ";
  import thirdSJ from './thirdSJ';
  import fourthSJ from './fourthSJ';
  import fifthSJ from './fifthSJ';
    export default {
      name: "index",
      components: {
        firstSJ,
        secondSJ,
        thirdSJ,
        fourthSJ,
        fifthSJ
      },
      data(){
        return {
          activeName:'five',
          searchBarFixed:false
        }
      }

    }
</script>

<style scoped>
.introduce-wrap{
}
/deep/ .el-tabs__header {
  position: absolute;
  top: 460px;
  left: 50%;
  margin-left: -600px;
  width: 1200px;
  z-index: 999;
}
  /deep/ .el-tabs__item{
    padding:0;
    width: 240px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color:#fff;
    font-size: 16px;
    background-color: #1566ef;
  }
/deep/ .el-tabs__item:hover{
  color: #fff;
}
  /deep/ .el-tabs__item.is-active{
    background: #1b7fff;
    color: #fff;
  }
  /deep/ .el-tabs__active-bar{
    background-color: rgba(0,0,0,0);
  }

</style>
