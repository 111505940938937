<template>
<div class="jingsan-wrap">
    <el-backtop :bottom="100">
        <div>
            <i class="el-icon-caret-top"></i>
        </div>
    </el-backtop>
    <div class="home-top">
        <div class="home-log">
            <img src="@/assets/images/logo-img.png" alt="">
        </div>
        <div  class="home-menu">
            <el-menu
                    :default-active="$route.path"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    router
            >
                <el-menu-item
                        v-for="(item,index) in menuList"
                        :index="item.hrefUrl"
                        :key="index"

                >
                    {{item.name}}
                </el-menu-item>
            </el-menu>
        </div>
        <div class="login-reg"
             v-show="!isLogin"
             @click="dialogVisible = true">
            登录/注册
        </div>
        <div class="user-info"  v-show="isLogin">
            <img  :src="avatar" alt="" >
            <span>{{nickname}}，</span>
            <span class="log-out" @click="handleLogOut">退出</span>
        </div>
    </div>
    <div class="contain">
        <el-carousel height="520px">
            <el-carousel-item>
                <router-link to="/jingsai/index"><img src="@/assets/images/banner1.png" alt=""></router-link>
            </el-carousel-item>
            <el-carousel-item>
                <router-link to="/jingsai/introduce"><img src="@/assets/images/banner2.png" alt=""></router-link>
            </el-carousel-item>
        </el-carousel>
        <router-view></router-view>
    </div>
    <!--<div class="bottom">-->
        <!--<div class="bottom-wrap">-->
            <!--<div class="bottom-link">-->
                <!--<h3 class="bottom-title">点击进入</h3>-->
                <!--<span>在线学习</span>-->
                <!--<span>线上报名</span>-->
            <!--</div>-->
            <!--<div class="bottom-wrap-left">-->
                <!--<h3 class="bottom-title">联系我们</h3>-->
                <!--<p>郭主任：13403716656</p>-->
                <!--<p>张院长：18003715289</p>-->
                <!--<p>邮 箱：13403716656@163.com</p>-->
            <!--</div>-->
            <!--<div class="bottom-wrap-left">-->
                <!--<h3 class="bottom-title">全省决赛</h3>-->
                <!--<p>比赛时间：2021年11月26-28日</p>-->
                <!--<p>比赛地址：郑州市第八十四中学</p>-->
                <!--<p>（郑州市郑东新区万通街与东周路交叉口）</p>-->
            <!--</div>-->
            <!--<div class="bottom-wrap-right">-->
                <!--<div class="contract-m">-->
                    <!--<img src="@/assets/images/qq.png" alt="" width="100" height="100"><br>-->
                    <!--<span class="txt">手机qq</span>-->
                <!--</div>-->
                <!--<div class="contract-m">-->
                    <!--<img src="@/assets/images/weixin.png" alt="" width="100" height="100"><br>-->
                    <!--<span class="txt">微信公众号</span>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
    <!--登录-->
    <el-dialog
            :visible.sync="dialogVisible"
            width="900px">
        <div class="logo-wrap">
            <div class="logo-left">
                <img src="@/assets/images/log-img.png" alt="" height="500">
            </div>
            <div  class="logo-right">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="username">
                        <el-input v-model="ruleForm.username" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item  prop="password">
                        <el-input v-model="ruleForm.password" placeholder="密码"  prefix-icon="el-icon-lock" type="password"></el-input>
                    </el-form-item>

                </el-form>
                <div class="waring">
                    还没有账号？<span @click="handleJumpReg">立即注册</span>
                </div>
                <el-button type="primary" @click="submitForm">登录</el-button>

            </div>
        </div>
    </el-dialog>
    <!--注册-->
    <el-dialog
            :visible.sync="regDialogVisible"
            width="900px">
        <div class="logo-wrap">
            <div class="logo-left">
                <img src="@/assets/images/reg-img.png" alt="" height="500">
            </div>
            <div  class="logo-right">
                <el-form :model="regRuleForm" :rules="regRules" ref="regRuleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="phone">
                        <el-input v-model="regRuleForm.phone" placeholder="手机号" prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item  prop="password">
                        <el-input v-model="regRuleForm.password" placeholder="8-20位，数字、字母、特殊字符任意两种" type="password"  prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                </el-form>
                <div class="waring">
                    已有账号？<span @click="handleJumplog">立即登录</span>
                </div>
                <el-button type="primary" @click="regSubmitForm">注册</el-button>
            </div>
        </div>
    </el-dialog>
</div>
</template>

<script>
    import Qs from "qs";
    import { regInfo,addUserInfo } from "@/api/register";
    import { login } from "@/api/login";
    import {getUserInfo } from "@/api/userInfo"
    export default {
        name: "index",
        data(){
            return {
                dialogVisible: false, //登录弹出框
                regDialogVisible:false, //注册弹出框
                isLogin:false,// 是否登录
                nickname: '',
                avatar: '',
                imgUrl:["banner1.png","banner2.png"],
                // activeIndex:"$route.path",
                menuList:[{
                    key:1,
                    name:'竞赛首页',
                    hrefUrl:'/jingsai/index'
                },{
                    key:2,
                    name:'赛项介绍',
                    hrefUrl:'/jingsai/introduce'
                },{
                    key:3,
                    name:'通知公告',
                    hrefUrl:'/jingsai/notice'
                },{
                    key:4,
                    name:'在线学习',
                    hrefUrl:''
                },{
                    key:5,
                    name:'联系我们',
                    hrefUrl:'/jingsai/contact'
                }],
                ruleForm: {
                    username: '',
                    password: '',
                },
                rules: {
                    username: [
                        {required: true, message: '用户名不能为空', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'},
                    ]
                },
                regRuleForm: {
                    phone: '',
                    password: '',
                },
                regRules: {
                    phone: [
                        {required: true, message: '手机号不能为空', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'},
                        { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' }
                    ]
                }
            }
        },
        created() {
            this.getUserInfo()
        },
        methods: {
            handleSelect(key) {
               if(key===""){
                   window.open("../index");
               }
            },
            getUserInfo(){
                if(window.localStorage.token){
                    let data=Qs.stringify({
                        token: window.localStorage.token
                    });
                    getUserInfo(data).then(res => {
                        if (res.data.code == 1) {
                            this.isLogin=true;
                            this.dialogVisible=false;
                            this.regDialogVisible=false;
                            this.nickname = res.data.data.nickname;
                            if(!window.localStorage.avatar){
                                this.avatar =this.$axios.iconUrl+res.data.data.avatar;
                            }else{
                                this.avatar = this.$axios.iconUrl+window.localStorage.avatar
                            }
                        } else {
                        }
                        return false;
                    })
                }

            },
            handleLogOut(){
                if(window.localStorage.token){
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_id");
                    localStorage.removeItem("class_id");
                    this.isLogin=false;
                }
            },
            // 登录
            submitForm(){
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let data=Qs.stringify({
                            username: this.ruleForm.username,
                            password: this.ruleForm.password
                        });
                      this.handleUserInfo(data);
                    } else {
                        return false;
                    }
                });
            },
            //登录
            handleUserInfo(params){
                login(params).then((res)=>{
                    const storage = window.localStorage;
                    if (res.data.code == 1) {
                        window.localStorage.setItem('token', res.data.data.userinfo.token)
                        storage.user_id = res.data.data.userinfo.id; //用户ID
                        storage.class_id = res.data.data.userinfo.classes_id;
                        this.$message({
                            message: '登录成功！',
                            type: 'success'
                        });
                        if (storage.token) {
                            this.getUserInfo();
                            // const loading = this.$loading({
                            //     lock: true,
                            //     text: '正在登录······',
                            //     customClass: 'create-isLoading',
                            //     background: 'rgba(255, 255, 255, 0.3)'
                            // });
                            // setTimeout(() => {
                            //     loading.close();
                            //     window.location = "index";
                            // }, 2000);
                        } else {
                            this.$message.error('登录异常');
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    return false;
                })
            },
            // 注册
            regSubmitForm(){
                this.$refs.regRuleForm.validate((valid) => {
                    if (valid) {
                        let params={
                            username: this.regRuleForm.phone,
                            password: this.regRuleForm.password
                        };
                        let userParams={
                            mobile: this.regRuleForm.phone,
                            sourcecode:"xingzhizuoxiaochengxu",
                            projectcode:"weizhi",
                            intentioncode:"N"
                        };
                        regInfo(params).then((res)=>{
                            if (res.data.code === 1) {
                              this.addUser(userParams);
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //添加客户
            addUser(params){
                addUserInfo(params).then((res)=>{
                    if (res.data.code === 1) {
                        let data=Qs.stringify({
                            username: this.regRuleForm.phone,
                            password: this.regRuleForm.password
                        })
                       this.handleUserInfo(data);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            handleJumpReg(){
                this.dialogVisible=false;
                this.regDialogVisible=true;
            },
            handleJumplog(){
                this.dialogVisible=true;
                this.regDialogVisible=false;
            }
        }
    }
</script>

<style scoped >

    .jingsan-wrap{
        width: 1920px;
        background:#fff
    }
    .contain{
        position: relative;
    }
    .home-top{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-log{
        display: flex;
        align-items: center;
        font-family: KaiTi;
    }
    .right-title{
        margin-left:5px;
        text-align: center;
    }
    .logo-title{
        font-size: 20px;
    }
    .en-txt{
        font-size: 14px;
        letter-spacing: 3px;
    }
    .home-menu{
        margin-left:100px;
    }
    .login-reg{
        margin-left:25px;
        text-align: center;
        width: 110px;
        height: 35px;
        line-height: 35px;
        background: linear-gradient(90deg, #0F98FF 0%, #1881FF 100%);
        border-radius: 20px;
        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;
    }

    /deep/ .el-menu--horizontal>.el-menu-item{
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3F3F3F;
    }
    /deep/ .el-menu.el-menu--horizontal{
        border-bottom: none;
    }
    /deep/ .el-menu--horizontal>.el-menu-item.is-active{
        border-bottom:6px solid #1881FF !important;
    }
    /deep/ .el-menu--horizontal>.el-menu-item{
        margin:0 25px;
        height:94px;
        line-height: 94px;
    }
    /deep/ .el-menu-item{
        padding:0;
    }
    /deep/ .el-carousel__indicators--horizontal{
        bottom: 80px;
    }
    .bottom{
        margin-bottom: 40px;
        width:1920px;
        height:200px;
        background-color: #242A35;
    }
    .bottom-wrap{
        display: flex;
        justify-content: space-between;
        width:1200px;
        margin:0 auto;
        padding-top: 30px;
    }
    .bottom-link span{
        display: inline-block;
        border: 1px solid #888888;
        color:#888;
        width: 90px;
        height: 30px;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        border-radius: 8px;
    }
    .bottom-title{
        margin-bottom: 34px;
        color: #FFFFFF;
        font-size: 20px;
    }
    .bottom-wrap-left{

    }
    .bottom-wrap-left p{
        font-size: 14px;
        line-height: 30px;
        color:#888888;
    }
    .bottom-wrap-right{
        display: flex;
    }
    .contract-m{
        margin-left:50px;
        text-align: center;
    }
    .contract-m img{

    }
    .contract-m .txt{
        display: inline-block;
        margin-top: 10px;
        color:#FFFFFF;
        font-size: 14px;
    }
    /*弹出框*/
    /deep/ .el-dialog__body{
        padding:0;
    }
    /deep/ .el-dialog__header{
        padding:0;
    }
    /deep/ .el-dialog{
        border-radius: 20px;
    }
   .logo-right /deep/ .el-button--primary{
       width: 320px;
        margin-top: 40px;
       margin-left: 100px;
    }
    .log-entrance{
        text-align: right;
        font-size: 14px;
    }
    .log-entrance a{
        color: #1980FF;
    }
    .logo-wrap{
        display: flex;
    }
    .logo-left{

    }
    .logo-right{
        margin:150px auto auto 10px;
        width: 420px;
    }
    .user-info img{
        width:30px;
        height:30px;
        border-radius:50%;
        vertical-align: middle;
        margin-right: 6px;
    }
    .user-info span{
        font-size: 14px;
        color: #1980FF;
    }
    .log-out{
        margin-left:8px;
        cursor: pointer;
    }
    .waring{
        margin-left: 100px;
    }
    .waring span{
        color: #1980FF;
        cursor: pointer;
    }
</style>
