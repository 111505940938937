<template>
    <div>
      <div class="zpsb-wrap">
        <p class="txt-info">水陆两栖车拔河比赛依靠设计合理的动力机械实现水陆两栖车扭矩和稳定性的最优化，进行力量的较量，将对手拉过中心线者获胜（车身任意部位越过中心线为准）</p>
        <div class="zpsb-info">
          <!--<router-link to="/jingsai/worksReport" class="sb-btn">作品申报</router-link><br>-->
          <span class="sb-btn" @click="handleWorksReport">作品申报</span><br>
          <a  class="sb-link" @click="handleOnload">点击附件下载</a><br>
          <span class="sb-time">申报截止时间：2021年10月29日23:59:59</span>
        </div>
      </div>
      <div class="jsdd-wrap">
        <h3 class="date-address-title">竞赛对象</h3>
        <p class="des">各省辖市、济源示范区、直管试点县（市）中小学有正式学籍在校学生（含职专）</p>
        <div  class="jsdx-item-box">
          <div class="jsdx-item">
            <img src="@/assets/images/school.png" alt="">
            <p class="jsdx-txt">年龄段：小学</p>
          </div>
          <div class="jsdx-item">
            <img src="@/assets/images/rkmd.png" alt="">
            <p class="jsdx-txt">参赛人数:1—2人/队</p>
          </div>
          <div class="jsdx-item">
            <img src="@/assets/images/zhidao.png" alt="">
            <p class="jsdx-txt">指导教师:限1人</p>
          </div>
        </div>

      </div>
      <div class="date-address">
        <div class="date-address-wrap">
          <h3 class="date-address-title">竞赛场地</h3>
          <div class="js-time-wrap">
            <div class="js-time-item">
              <img  src="@/assets/images/changdi1.png" alt="">
            </div>
            <div class="js-time-item">
              <img  src="@/assets/images/changdi2.png" alt="">
            </div>
          </div>
          <p class="jscd-txt-desc">
            （1）赛场分为水面和地面块场地，地面为喷绘相纸场地纸，场地地面尽量保持平整，水面为小型水池，水面开始比赛前保持水面平静
          </p>
          <p class="jscd-txt-desc">
            （2）中线和双方预备位置标线均为黑线，直接喷绘在场地表面或在水面标识，中央黑线宽2cm，长50cm;两端黑线距离中央黑线30cm，线宽1cm，长30cm
          </p>
        </div>
      </div>
      <div class="zpyq-wrap">
        <h3 class="sx-set-title">作品要求</h3>
        <div class="zpyq-txt-desc">
          <p class="desc-item">
            （1）水陆两栖车必须是能够在地面和水面上移动的“轮式”设计，不得采用固定于地面不动的设计；选手可采取按下按钮或给传感器一个触发信号的方式启动水陆两栖车，启动后水陆两栖车必须自动运行，禁止使用任何遥控方式。用于启动水陆两栖车的按钮或传感器必须固定于水陆两栖车表面易于操作的位置。每队选手限带一台水陆两栖车参加比赛
          </p>
          <p class="desc-item">
            （2）使用的器材必须符合创客的定义，不得使用电动玩具、或电源直接连接马达制成的电动车来进行比赛；参加本比赛的水陆两栖车不限套材
          </p>
          <p class="desc-item">
            （3）水陆两栖车必须能以任何角度均可纳入内径为20cm的开口圆筒中，重量不得超过1kg
          </p>
          <p class="desc-item">
            （4）水陆两栖车的设计制作应由学生独立完成
          </p>
          <p class="desc-item">
            （5）水陆两栖车使用最多6节1.5V干电池或不高于9V的充电电池（组）供电，盒盖应便于开启或透明，以便于检录时裁判员检查机器
          </p>
          <p class="desc-item">
            （6）水陆两栖车最多使用2个马达
          </p>
          <p class="desc-item">
            （7）水陆两栖车全部动力只可来源于马达，不得使用其他装置给水陆两栖车提供额外动力
          </p>
          <p class="desc-item">
            （8）水陆两栖车上的任何部件不得影响其它水陆两栖车的正常运行
          </p>
          <p class="desc-item">
            （9）在水陆两栖车后部中间位置，必须具备专用的“环形装置”供裁判员固定绳索，该装置属于水陆两栖车整体尺寸的一部分；当绳索固定于此装置后，绳索水平伸直后距地面的高度不可超过5cm
          </p>
          <p class="desc-item">
            （10）水陆两栖车不得采用任何卷曲绳索的策略
          </p>
          <p class="desc-item">
            （11）除轮子、车身等必要的运动部件及规则允许的其他部件外，水陆两栖车不得有任何结构接触地面、对方水陆两栖车和绳索
          </p>
          <p class="desc-item">
            （12）水陆两栖车不得使用任何方式辅助固定于地面(例如在轮子上涂抹粘性物质)，检录时，如水陆两栖车与地面接触的部件可以共同粘起张A4打印纸，即视为违规
          </p>
          <p class="desc-item">
            （13）比赛过程中，水陆两栖车必须采取直线行走的策略，不得主动向场地两边移动
          </p>
        </div>
      </div>
      <div class="date-address jssz-wrap">
        <div class="date-address-wrap">
          <h3 class="date-address-title" style="color:#fff; border-bottom: 1px solid rgba(255,255,255,0.2)">竞赛赛制</h3>
          <p class="des">水陆两栖车拔河竞赛为小组循环赛</p>
          <div class="js-time-wrap">
            <div class="jssz-txt-item">
              小组内随机匹配对手进行对抗，获胜得3分，输的得1分，平局各得2分；未到场不得分，对手直接判胜得3分。根据参赛情况，每个队伍都将有3-5次对抗机会，最后计算每队总得分情况，根据得分情况评进行排名。若得分相同，车轻者获胜。具体对阵情况及对阵场次将在比赛前由裁判向选手告知，各选手务必听从裁判指令。
            </div>
            <div class="jssz-txt-item-right">
               <div class="right-top">
                 比赛过程中挂绳及零部件掉落者判负，胜负已分的情况下，挂绳及零件掉落不对比赛结果产生影响。
               </div>
              <div class="right-bottom">
                每局比赛时间为1分钟，1分钟内两车未决出胜负判定为平局；两车僵持不动没有实质性进展的情况下超过5秒判定为平局。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "firstSJ",
      methods:{
        handleWorksReport(){
          //选择判断是否登录，只有登录才能作品申报
          if(window.localStorage.token){
            this.$router.push({ path: '/jingsai/worksReport', query: { param: 1 }})
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
          }
        },
        handleOnload(){
          if(window.localStorage.token){
            window.open(`${this.$axios.iconUrl}/uploads/jingsai/水陆两栖车拼装竞速赛.rar`);
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
            return false;
          }
        }
      }
    }
</script>

<style scoped>
  .zpsb-wrap,
  .jsdd-wrap{
    width:1200px;
    margin:0 auto;
  }
  .txt-info{
    margin-top: 39px;
    text-indent: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
  }
  .zpsb-info{
    margin-top:24px;
    text-align: center;
    line-height: 50px;
  }
  .sb-btn{
    display: inline-block;
    font-size: 30px;
    margin-bottom: 8px;
    width:300px;
    height:80px;
    line-height: 80px;
    text-align: center;
    color:#fff;
    background-color: #ff7e24;
    border-radius: 10px;
    cursor: pointer;
  }
  .sb-link{
  font-size: 20px;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }
  .sb-link:link{
    border-bottom:1px solid #000;
  }
  .sb-time{
    font-size: 20px;
  }
  .jsdd-wrap .date-address-title{
    margin: 100px auto 10px auto;
    padding-top: 20px;
    width: 500px;
    height: 120px;
    text-align: center;
    font-size: 34px;
    color: #000;
    font-weight: bold;
    border-bottom: 1px solid #1980FF;
  }
  .jsdd-wrap .des{
    position: relative;
    top: -66px;
    text-align: center;
    font-size: 20px;
    color: #000000;
  }
  .jsdx-item-box{
    display: flex;
    justify-content: space-around;
    margin-top:92px;
  }
  .jsdx-item{
text-align: center;
  }
  .jsdx-item img{

  }
  .jsdx-item .jsdx-txt{
    margin-top:30px;
    color:#000000;
    font-size: 30px;
  }
  .date-address{
    background-color: #F0F8FF;
    margin-top:198px;
    width: 1920px;
    height: 748px;
  }
  .date-address-wrap{
    width: 1200px;
    margin:0 auto;
  }
  .date-address .date-address-title{
    margin: 0 auto;
    padding-top: 20px;
    width: 500px;
    height:150px;
    line-height: 150px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #000;
  }
  .js-time-wrap{
    display: flex;
    justify-content: space-around;
  }
  .js-time-item{
    margin-right:40px;
    margin-top:37px;
    width: 540px;
    height: 300px;
    background-color: #1980FF;
    border-radius: 20px;
  }
  .jscd-txt-desc{
    margin-top:30px;
    font-size: 20px;
    color: #000;
  }
  .zpyq-wrap{
    background: url("../../../assets/images/zp-bg.png") no-repeat;
    margin-top: 100px;
  }
  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:100px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .zpyq-txt-desc{
    width:1200px;
    margin:60px auto;
    color:#000;
    font-size: 20px;
  }
  .desc-item{
    line-height: 30px;
    margin-bottom:30px;
  }
  .jssz-wrap{
    height:660px;
    background: url("../../../assets/images/sz-bg.jpg") no-repeat;
  }
  .jssz-wrap .des{
    position: relative;
    top: -45px;
    text-align: center;
    font-size: 20px;
    color: #fff;
  }
  .jssz-txt-item{
    margin-top:37px;
    background-color: #FFFFFF;
    border-radius: 20px;
    font-size: 20px;
    color:#000;
    width: 440px;
    height: 200px;
    line-height: 30px;
    padding: 51px 31px;
  }
  .jssz-txt-item-right .right-top,
  .jssz-txt-item-right .right-bottom{
    width: 400px;
    height: 80px;
    line-height: 30px;
    background-color: #FFFFFF;
    border-radius: 20px;
    font-size: 20px;
    color:#000;
  }
  .jssz-txt-item-right .right-top{
    margin-top:39px;
    padding: 20px 30px;
  }
  .jssz-txt-item-right .right-bottom{
    height: 100px;
    padding: 20px 30px;
    margin-top:40px;
  }
</style>

