import $axios from '../untils/api';


//数据字典
export function getCategory(value) {
    return $axios.get('/category/getCategory',{
        params: {
            type:value
        }
    })
}

//作品申报
export function addWorksReprot(params) {
    return $axios.post('/usercomplog/add',params)
}
//申报记录列表
export function getWorksReprot(paramsValue) {
    return $axios.get('/usercomplog/get',{
        params:paramsValue
    })
}

//根据赛项获得用户非第一次申报信息
export function getWorksReprotLastest(paramsValue){
    return $axios.get('/usercomplog/getlastest',{
        params: paramsValue
    })
}
