<template>
    <div>
        <h3 class="notice-title">
            <span class="title-txt">新闻动态</span><br>
            <span class="title-eng">News Center</span>
        </h3>
        <div class="notice-detail">
            <h2 class="notice-detail-title">2021年河南省中小学生科技体育系列竞赛规程</h2>

            <p  class="notice-detail-content">
                 一、组织单位<br/>
                主办单位：河南省教育厅<br/>
                承办单位：河南省学生体育总会科技体育分会<br/>
                郑州市郑东新区教体局<br/>
                郑州市第八十四中学<br/>
                协办单位：河南行知塾教育科技集团

            </p>
            <p  class="notice-detail-content">
                二、比赛时间和地点<br/>
                日期：2021年11月26-28日<br/>
                地点：郑州市第八十四中学
            </p>
            <p  class="notice-detail-content">
                三、参赛范围及组别<br/>
                各省辖市、济源示范区、直管试点县（市）中小学在校学生（含职专）。竞赛设中学男子组、小学男子组、中学女子组、小学女子组。

            </p>
            <p  class="notice-detail-content">
                四、竞赛办法<br/>
                第一阶段：各地有条件的可申请在当地举办分站、分项赛，需提前将竞赛方案和计划上报河南省学生体育总会科技体育分会。选拔竞赛时间：2021年5月-10月。<br/>
                分站赛与分项赛项目设置可参照2021年河南省中小学生科技体育系列竞赛规程和规则执行。<br/>
                第二阶段：全省总决赛。
            </p>
            <p  class="notice-detail-content">
                五、竞赛项目<br/>
                （一）校园纸飞机模型竞赛<br/>
                1.悬浮纸飞机赛<br/>
                2.纸飞机靶标赛<br/>
                3.纸飞机掷准赛<br/>
                （二）校园室内遥控飞行<br/>
                1.直升机场地障碍赛<br/>
                2.直升机绕杆竞速赛<br/>
                （三）校园车辆模型竞赛<br/>
                1.三项全能直线赛<br/>
                2.遥控车场地赛（1/27）<br/>
                3.二对二桌面台球赛<br/>
                4.轨道车拼装竞速赛<br/>
                5.水陆两栖车拼装竞速赛<br/>
                （四）校园航海模型<br/>
                1.“沙船”、“绿眉毛”帆船模型制作赛<br/>
                2.“新自由号”遥控快艇环游赛<br/>
                3.“极光号”遥控快艇追逐赛<br/>
                4.中国“海警”船模型航行赛<br/>
                5.“蛟龙”号深潜考察船模型定点考察巡航赛<br/>
                6.“英雄”号鱼雷快艇航行赛<br/>
                7.“昆明”号导弹驱逐舰航行赛<br/>
                8.“白马湖”号280、400遥控帆船绕标赛<br/>
                9.“沂蒙山”号两栖登陆舰遥控赛<br/>
                10.“新自由号”水上足球团体赛<br/>
                11.遥控快艇对抗赛<br/>
                12.环保收集船创新赛<br/>
                （五）校园建筑模型<br/>
                1.“缤纷童年”涂装木屋制作赛（小学组）<br/>
                2.“绿野春天”花园别墅制作赛<br/>
                3.“中华庭园”古典民居创意赛<br/>
                4.“梦想家园”创意小筑制作赛<br/>
                5.“美丽乡村”场景设计赛<br/>
                6.“宅门”涂装设计赛<br/>
                7.“国际会展中心”设计制作赛<br/>
                8.“援梦之城”城堡创意设计赛<br/>
                9. 桐木框架结构承重赛<br/>
                （六）庆祝建党100周年，“红心向党 匠心育人”红色主题教育竞赛<br/>
                1.木质、纸质南湖红船仿真模型制作赛<br/>
                2.电动南湖红船模型航行赛<br/>
                3.“功勋号”鱼雷快艇遥控赛<br/>
                4“毛泽东故居”场景模型制作赛<br/>
                5.“中国共产党一大会址”场景模型制作赛<br/>
                6.“遵义会址”场景模型制作赛<br/>
                7.“延安精神”红色主题场景设计模型<br/>
                8.“民族大联欢”叠叠高挑战赛（小学组）<br/>
                （七）校园“未来工程师”项目<br/>
                1.创意花窗<br/>
                2.“千机变”室内手掷飞机联赛<br/>
                3.“过山车”挑战赛<br/>
                4.投石车积分赛<br/>
                5.“领航者”无人机救援任务赛<br/>
                （八）校园模拟飞行竞赛<br/>
                1.Z-本场五边飞行争霸赛<br/>
                2.红牛朗利特特技竞速<br/>
                3.Y-穿越气柱竞速<br/>
                （九）校园无线电测向<br/>
                1.3.6MHz操场无线电测向<br/>
                2.144MHz操场无线电测向<br/>
                3.6MHz盲人无线电测向<br/>
                4.3.6MHz无线电测向标台赛<br/>
                （十）校园人工智能和头脑奥林匹克（OM）创新赛<br/>
                1.“深海救援”挑战赛（小学组）<br/>
                2.“智慧城市”造物智能设计赛<br/>
                3.“童心向党心”电子创意设计赛<br/>
                4.头脑奥林匹克（OM）创新赛（题目以2021年官方发布为准）<br/>
            </p>
            <p  class="notice-detail-content">
                六、竞赛和组织办法<br/>
                （一）竞赛执行《2021年河南省中小学生科技体育竞赛规则》及相关竞赛的补充规定。相关规则及赛事服务等事项，将在河南省科技体育协会QQ工作群（542415986）和微信公众号（“河南省学生体育总会科技体育分会”）发布。<br/>
                （二）竞赛仲裁委员会、裁判委员会人员由河南省学生体育总会科技体育协会选派。<br/>
                （三）裁判员均需通过赛前集训。<br/>
                （三）报名参赛队不足3个的团体项目以及报名不足6人的个人项目，合并组别进行比赛。并组后仍不足规定数额的取消该项竞赛。<br/>
                （四）各竞赛大项及项目之间不得兼项，无线电项目兼项按规则执行，团体项目每单位限报1-2组。<br/>
                （五）竞赛采取领队负责制。各参赛单位限报领队1人，辅导教师1人，每个代表队运动员人数不少于10人，不超过30人。每代表队运动员人数超过10人，每增加10人，可增报一名辅导教师，以此类推。<br/>
                （六）参赛运动员器材自备，参加竞赛使用的器材必须符合竞赛统一技术标准。<br/>
                （七）参赛单位须填写自愿参赛责任及风险告知书，提供学生健康证明，参赛运动员必须办理人身意外伤害保险。<br/>
                （八）学校在组织进行竞赛活动时要制定新冠肺炎疫情防控方案，严格执行防疫各项规定，确保安全顺利进行。<br/>
                （九）河南省学生体育总会科技体育分会将组织基层辅导教师参加相关培训、入校送培、特色课程建设咨询和对外交流活动。<br/>
                （十）本次赛事将选拔优秀选手参加国家相关赛事。<br/>
            </p>
            <p  class="notice-detail-content">
                七、运动员参赛条件<br/>
                凡报名参赛的运动员必须是思想品德好、身体健康，适合参加比赛有正式学籍在校学习的学生。
            </p>
            <p  class="notice-detail-content">
                八、录取名次与奖励<br/>
                （一）各单项组别参赛人数达到要求的，奖励前八名颁发证书，前三名颁发奖牌。综合团体成绩计算依据各队运动员参加校园纸飞机模型、校园室内遥控直升机、校园车辆模型、校园航海模型、校园建筑模型、校园模拟飞行、“红心向党 匠心育人”红色主题教育竞赛、校园未来工程师教育竞赛、校园无线电测向竞赛、校园人工智能和头脑奥林匹克（OM）项目等十大项，所获得名次累计积分评选，报名参赛不足三大项的参赛队，不参加组织奖评选。各竞赛项目分设一、二、三等奖，录取比例分别为15％、20％、25％，未获得等级奖的选手，颁发优胜奖。<br/>
                （二）在校园纸飞机模型、校园室内遥控直升机、校园车辆模型、校园航海模型、校园建筑模型、校园模拟飞行、“红心向党 匠心育人”红色主题教育竞赛、校园未来工程师教育竞赛、校园无线电测向竞赛中、校园人工智能和头脑奥林匹克（OM）项目报项不低于三大项，并且辅导学生获得一项第一名或两项前三名成绩，可评选“科技体育社团优秀辅导教师”。对庆祝建党100周年“红心向党 匠心育人”红色主题教育竞赛活动中成绩突出的学校进行表彰。
            </p>
            <p  class="notice-detail-content">
                九、报名与报到<br/>
                （一）报名：参加全省总决赛，由各省辖市教育行政部门组织，以学校为单位报名，于2021年11月5日前，通过电子邮件将excel格式报名表，报至13403716656@163.com邮箱（报名文件名必须注明单位、项目）报名表格可到河南科技体育分会QQ工作群下载（群号：542415986）。报名表不规范、逾期报名不予受理。竞赛报到时提交正式报名表（必须和电子报名表内容一致）。<br/>
                （二）报到：各参赛队请于2021年11月26日15：00-18:00，到郑州市第八十四中学（郑州市郑东新区万通街与东周路交叉口）报到。<br/>
                （三）2021年11月26日18:30，召开竞赛领队教练员裁判员联席会议。<br/>
                报到时须交验：<br/>
                1.打印并盖有公章的纸质报名表（须与电子报名表一致）；<br/>
                2.参赛选手身份证明或学籍证明；<br/>
                3.参赛选手健康证明；<br/>
                4．自愿参赛责任及风险告知书；<br/>
                5.人身意外伤害保险单据（含路途和比赛期间）。<br/>
                6.代表队队旗。<br/>
                联系人：郭常有  电话：13403716656；<br/>
                张志博  电话：18003715289。
            </p>
            <p  class="notice-detail-content">
                十、裁判员和仲裁人员的选调<br/>
                裁判长、裁判员和仲裁委员由河南省学生体育总会科技体育协会选调。
            </p>
            <p  class="notice-detail-content">
                十一、其它<br/>
                （一）赛事费用由承办单位解决，各参赛队交通、食宿费等费用由派出单位承担。<br/>
                （二）各参赛代表队报到时自备3米×2米旗一面。

            </p>
            <p  class="notice-detail-content">
                十二、本规程解释权归主办单位，未尽事宜，另行通知。
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "noticeDetailOne"
    }
</script>

<style scoped>
    .notice-title{
        margin-top: 33px;
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 40px;
    }
    .title-txt{
        color: #1980FF;
        font-size: 30px;
    }
    .title-eng{
        position: relative;
        color: #3F3F3F;
        font-size: 20px;
    }
    .title-eng:before{
        content: "";
        position: absolute;
        left: 20px;
        bottom: -10px;
        display: inline-block;
        width: 80px;
        height: 4px;
        background-color: #1980FF;
    }
    .notice-detail{
        width: 865px;
        margin:40px auto 100px auto;
    }
    .notice-detail-title{
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
        color:#3F3F3F;
        font-weight: bold;
    }
    .notice-source{
        margin-top: 20px;
        color:#3F3F3F;
    }
    .notice-detail-content{
        margin-top: 30px;
        line-height: 30px;
        font-size: 14px;
        color:#000;
    }
</style>
