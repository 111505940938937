import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import $axios from './untils/api'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import Video from 'video.js'
import "videojs-markers";
import "videojs-markers/dist/videojs.markers.css";
import 'video.js/dist/video-js.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Axios from 'axios'
// import 'lib-flexible'
// Axios.defaults.headers.post['Content-type'] = 'text/plain'
Vue.prototype.$video = Video
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.prototype.$axios = $axios;
Vue.config.productionTip = false
NProgress.configure({
  easing:'ease',
  speed: 1500,
  showSpinner:false,
  trickleSpeed: 200,
  minimum: 0.3
})

router.beforeEach((to, from , next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();

  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next();
})

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
