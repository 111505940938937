import axios from "axios";
import Vue from "vue";
import router from "../router/index.js";

let base = "http://yoback2.xzs51.com";

// //测试环境
// //注册用户头像
// let baseIconUrl = "http://backtest.yocode.com.cn";
//let baseUrl = "http://backtest.yocode.com.cn/api";
//正式环境
//注册用户头像
// let baseIconUrl = "http://back.yocode.com.cn";

let baseIconUrl = "http://static.yocode.com.cn";
let baseUrl = "https://back.yocode.com.cn/api";
function open(msg) {
  Vue.prototype.$notify.error({
    title: "错误",
    message: msg,
  });
}

//请求拦截
axios.interceptors.request.use((config) => {
  let token = window.localStorage.getItem("token");
  if (window.localStorage.getItem("token")) {
    config.headers.Authorization = token; //把token存入请求头
  }
  return config;
});

// axios返回拦截
axios.interceptors.response.use(
  (response) => {
    if (response.data.status == 500) {
      open("服务器返回500，请排查所填请求参数是否正确");
    } else if (response.data.status == 401) {
      open("登录状态过期, 请重新登录!");
      localStorage.removeItem("token");
      router.push("/");
    }
    return response;
  },
  (error) => {
    if (error.response.data.code == 401) {
      // open('登录状态过期, 请重新登录!')
      localStorage.removeItem("token");
      router.push("/");
    }
    if (error.response) {
      switch (error.response.status) {
        case 500:
          open("接口请求出现异常");
      }
      return Promise.reject(errorj.response.data);
    } else {
      open("前端请求未找到相应地址，请检查服务器是否正常，网络连接是否正常");
    }
  }
);

const post = (url, query) => {
  return axios.post(baseUrl + url, query);
};

const get = (url, query) => {
  return axios.get(baseUrl + url, query);
};

const $axios = {
  post: post,
  get: get,
  url: baseUrl,
  iconUrl: baseIconUrl,
};

export default $axios;

/**
 * 封装后的使用方式
 * 在main.js中引入api.js文件,并挂载到Vue原型上(已引入并挂载)
 * 在需要使用的文件中直接按照下面方法使用即可
 * get请求使用方式
 * this.$axios.get('/api/login', {
 * 		params: {
 * 				// 这里写的是需要传递给后台的参数  如:
 * 				id: '0001',
 * 				name: '张三'
 * 				...
 * 		}
 * }).then(data => {
 * 		console.log(data) // 这里是后台返回的数据
 * }).catch( err => {
 * 		console.log(err)  // 这里是请求出错时的错误信息
 * })
 *
 * post请求使用方式
 * this.$axios.post('/api/login', {
 * 		// 这里写的是需要传递给后台的参数  如:
 * 		id: '0001',
 * 		name: '张三'
 * 		...
 * }).then( data => {
 * 		console.log(data) // 这里是后台返回的数据
 * }).catch( err => {
 * 		console.log(err)  // 这里是请求出错时的错误信息
 * })
 *
 * 如果使用base   则直接使用  this.$axios.url
 */
