import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Index from '@/views/jingsai';
import Home from '@/views/jingsai/home';
import Introduce from '@/views/jingsai/introduce';
import Notice from '@/views/jingsai/notice';
import NoticeDetail from '@/views/jingsai/notice/noticeDetail';
import NoticeDetailOne from '@/views/jingsai/notice/noticeDetailOne';
import NoticeDetailTwo from '@/views/jingsai/notice/noticeDetailTwo';
import NoticeDetailThree from '@/views/jingsai/notice/noticeDetailThree';
import NoticeDetailFour from '@/views/jingsai/notice/noticeDetailFour';
import NoticeDetailFive from '@/views/jingsai/notice/noticeDetailFive';
import NoticeDetailSix from '@/views/jingsai/notice/noticeDetailSix';

import WorksReport from '@/views/jingsai/worksReport';
import Contact from '@/views/jingsai/contact';
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    meta:{isShow:true},
    component: () => import(/* webpackChunkName: "about" */ '../components/home.vue')
  },
  {
    path: '/curriculum',
    name: 'Curriculum',
    meta:{isShow:true},
    component: () => import(/* webpackChunkName: "about" */ '../components/curriculum.vue')
  },
  {
    path: '/teachingmode',
    name: 'Teachingmode',
    meta:{isShow:true},
    component: () => import(/* webpackChunkName: "about" */ '../components/teachingmode.vue')
  },
  {
    path: '/process',
    name: 'Process',
    meta:{isShow:true},
    component: () => import(/* webpackChunkName: "about" */ '../components/process.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/index/userInfo',
    name: 'UserInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/userInfo.vue')
  },
  {
    path: '/index/curriculum',
    name: 'curriculum',
    component: () => import(/* webpackChunkName: "about" */ '../views/curriculum.vue')
  },
  {
    path: '/index/playVideo',
    name: 'playVideo',
    component: () => import(/* webpackChunkName: "about" */ '../views/playVideo.vue')
  },
  {
    path: '/index/myWork',
    name: 'myWork',
    component: () => import(/* webpackChunkName: "about" */ '../views/myWork.vue')
  },
  {
    path: '/index/expandClass',
    name: 'expandClass',
    component: () => import(/* webpackChunkName: "about" */ '../views/expandClass.vue')
  },
  {
    path: '/index/noCourse',
    name: 'noCourse',
    component: () => import(/* webpackChunkName: "about" */ '../views/noCourse.vue')
  },
  {
    path: '/index/myWork/classType',
    name: 'classType',
    component: () => import(/* webpackChunkName: "about" */ '../views/classType.vue')
  },
  {
    path: '/jingsai',
    name: 'Jingsai',
    meta:{isShow:false},
    redirect: '/jingsai/index',
    component: () => import(/* webpackChunkName: "about" */ '../views/jingsai/index.vue'),
    children:[{
      path: '/jingsai/index',
      name: '首页',
      component: Home,
    },{
      path: '/jingsai/introduce',
      name: 'introduce',
      component: Introduce
    },
      {
        path: '/jingsai/notice',
        name: 'notice',
        component: Notice
      },
      {
        path: '/jingsai/noticeDetail/:id',
        name: 'noticeDetail',
        component: NoticeDetail
      },
      {
        path: '/jingsai/noticeDetailOne',
        component: NoticeDetailOne
      },
      {
        path: '/jingsai/noticeDetailTwo',
        component: NoticeDetailTwo
      },
      {
        path: '/jingsai/noticeDetailThree',
        component: NoticeDetailThree
      },
      {
        path: '/jingsai/noticeDetailFour',
        component: NoticeDetailFour
      },
      {
        path: '/jingsai/noticeDetailFive',
        component: NoticeDetailFive
      },
      {
        path: '/jingsai/noticeDetailSix',
        component: NoticeDetailSix
      },
      {
        path: '/jingsai/worksReport',
        name: 'worksReport',
        component: WorksReport
      },
      {
        path: '/jingsai/contact',
        name: 'contact',
        component: Contact
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
