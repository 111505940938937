<template>
<div>
  <div class="zpsb-wrap">
    <p class="txt-info">本次电子创意设计赛主题选定为“童心向党心”，旨在庆祝中国共产党成立100周年，献礼建党100周年庆，促进学生开展党史学习教育，感受红色文化、了解红色人物。童心向党，致敬华诞</p>
    <div class="zpsb-info">
      <span class="sb-btn" @click="handleWorksReport">作品申报</span><br>
      <a  class="sb-link" @click="handleOnload">点击附件下载</a><br>
      <span class="sb-time">申报截止时间：2021年10月29日23:59:59</span>
    </div>
  </div>
  <div class="jsdx-wrap">
    <div class="jsdx-content">
      <div style="text-align: center;margin-top: 100px">
        <img src="@/assets/images/csdx_txt.png" alt="">
      </div>
      <p class="desc"><span>各省辖市、济源示范区、直管试点县（市）中小学有正式学籍在校学生（含职专）</span></p>
      <div style="text-align: center;margin-top: 66px">
        <img src="@/assets/images/csdx_img.png"  alt="">
      </div>
      <div  class="jsdx-item-box">
        <div class="jsdx-item">
          <p class="jsdx-txt">小学I组（1-3年级）</p>
        </div>
        <div class="jsdx-item">
          <p class="jsdx-txt">小学II组(4-6年级）</p>
        </div>
      </div>
      <!--<h3 class="sx-set-title">竞赛对象</h3>-->
      <!--<p class="desc"><span>参赛选手应该在赛前完成参赛作品的制作，并上传提交每个作品由一名学生独立完成，须且仅限配备1名指导教师</span></p>-->
      <!--<div  class="jsdx-item-box">-->
        <!--<div class="jsdx-item">-->
          <!--<img src="@/assets/images/school.png" alt="">-->
          <!--<p class="jsdx-txt">小学I组：1-3年级</p>-->
        <!--</div>-->
        <!--<div class="jsdx-item">-->
          <!--<img src="@/assets/images/rkmd.png" alt="">-->
          <!--<p class="jsdx-txt">小学II组：4-6年级</p>-->
        <!--</div>-->
      <!--</div>-->
      <!--<p class="js-txt-waring">*学生必须是截止到2019年5月底前仍然在校的学生</p>-->
    </div>
  </div>
  <div class="jssz-wrap">
    <div class="jssz-content">
      <!--<h3 class="sx-set-title">竞赛赛制</h3>-->
      <div style="text-align: center;margin-top: 66px">
        <img src="@/assets/images/cszp_txt.png" alt="">
      </div>
        <p class="desc-item">
       作品包括互动艺术、竞技类、实用工具、科学探索四类。<br/><br/>
        1、<span style="color:#1980FF;">互动艺术:</span>引入绘画、录音、摄影等多媒体手段，用新媒体互动手法实现音乐、美术方面的创意展示。<br/>
        2、<span style="color:#1980FF;">各种竞技类:</span>探险类、角色扮演类、球类、棋牌类游戏。<br/>
        3、<span style="color:#1980FF;">实用工具:</span>有实用价值、能解决学习生活中的实际问题的程序工具。<br/>
        4、<span style="color:#1980FF;">科学探索:</span>现实模拟、数学研究、科学实验等等各学科的趣味性展示与探究。
        </p>

      <!--<div class="jssz-txt-top">-->
        <!--小组内随机匹配对手进行对抗，获胜得3分，输的得1分，平局各得2分；未到场不得分，对手直接判胜得3分。根据参赛情况，每个队伍都将有3-5次对抗机会，最后计算每队总得分情况，根据得分情况评进行排名。若得分相同，车轻者获胜。具体对阵情况及对阵场次将在比赛前由裁判向选手告知，各选手务必听从裁判指令。-->
      <!--</div>-->
      <!--<div class="jssz-txt-bottom">-->
        <!--<div class="jssz-txt-bottom-con">比赛过程中挂绳及零部件掉落者判负，胜负已分的情况下，挂绳及零件掉落不对比赛结果产生影响。</div>-->
        <!--<div class="jssz-txt-bottom-con">每局比赛时间为1分钟，1分钟内两车未决出胜负判定为平局；两车僵持不动没有实质性进展的情况下超过5秒判定为平局。</div>-->
      <!--</div>-->
    </div>
  </div>
  <div style="background-color: #f4f4f4; overflow: hidden">
    <div style="text-align: center;margin-top: 66px">
      <img src="@/assets/images/zpyq_txt.png" alt="">
    </div>
    <p class="desc-item" style="width: 1200px;margin: 42px auto;">
      1、<span style="color:#1980FF;">原创性</span>:作品必须为作者原创，无版权争议。若发现涉嫌抄袭或侵犯他人著作权的行为，一律取消评奖资格。如涉及作品原创问题的版权纠纷，由申报者承担责任。<br>
      2、<span style="color:#1980FF;">创新创造</span>:作品主题鲜明,创意独特,表达形式新颖，构思巧妙，充分发挥想象力。<br>
      3、<span style="color:#1980FF;">设计思想</span>:作品构思完整，内容主题清晰，有始有终;创意来源于学习与生活，积极健康，反应青少年的年龄心智特点和玩乐思维。<br>
      4、<span style="color:#1980FF;">用户体验</span>:观看或操作流程简易,无复杂，多余步骤;人机交互顺畅，用户体验良好。<br>
      5、<span style="color:#1980FF;">艺术审美</span>:界面美观、布局合理，给人以审美愉悦和审美享受;角色造型生动丰富，动画动效协调自然，音乐音效使用恰到好处;运用的素材有实际意义，充分表现主题。<br>
      6、<span style="color:#1980FF;">程序技术</span>:合理正确地使用编程技术，程序运行稳定、流畅、高效，无明显错误;程序结构划分合理，代码编写规范，清晰易读;通过多元、合理的算法解决复杂的计算问题，实现程序的丰富效果。<br><br>
      作品著作权归作者所有，使用权由作者和主办单位共享。主办单位对获奖作品有宣传和展示的权利。
    </p>
  </div>
</div>
</template>

<script>
    export default {
        name: "fifthSJ",
      methods:{
        handleWorksReport(){
          //选择判断是否登录，只有登录才能作品申报
          if(window.localStorage.token){
            this.$router.push({ path: '/jingsai/worksReport', query: { param: 5 }})
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
          }
        },
        handleOnload(){
          if(window.localStorage.token){
            window.open("");
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
            return false;
          }
        }
      }
    }
</script>

<style scoped>
  .zpsb-wrap,
  .jssz-wrap{
    width:1200px;
    margin:0 auto;
  }
  .txt-info{
    margin-top: 39px;
    text-indent: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
  }
  .zpsb-info{
    margin-top:24px;
    text-align: center;
    line-height: 50px;
  }
  .sb-btn{
    display: inline-block;
    font-size: 30px;
    margin-bottom: 8px;
    width:300px;
    height:80px;
    line-height: 80px;
    text-align: center;
    color:#fff;
    background-color: #ff7e24;
    border-radius: 10px;
    cursor: pointer;
  }
  .sb-link{
    font-size: 20px;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }
  .sb-link:link{
    border-bottom:1px solid #000;
  }
  .sb-time{
    font-size: 20px;
  }
  .jsdx-wrap{
    background-color: #f4f4f4;
    margin-top: 100px;
    overflow: hidden;
    padding-bottom: 50px;
  }
  .jsdx-content .desc{
    position: relative;
    margin-top: 50px;
    text-align: center;
    color:#000000;
    font-size: 20px;
  }
  .sx-set-title{
    margin: 0 auto;
    padding-top: 40px;
    width: 500px;
    height:140px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #fff;
  }
  .jsdx-item-box{
    display: flex;
    justify-content: space-around;
    margin:92px auto 0 auto;
    width:992px;
  }
  .jsdx-item{
    text-align: center;
    width: 400px;
    height: 80px;
    line-height: 80px;
    background-color: #4286EE;
    border-radius: 50px;
  }
  .jsdx-item img{
    margin-top: 62px;
  }
  .jsdx-item .jsdx-txt{
    color:#FFFFFF;
    font-size: 20px;
  }
  .js-txt-waring{
    color:#1980FF;
    font-size: 20px;
    text-align: center;
    margin-top: 80px;
    padding-bottom: 30px;
  }
  .jssz-wrap{
    margin-top: 100px;
  }
  .jssz-wrap .sx-set-title{
    color:#1980FF;
    border-bottom: 1px solid #000;
  }
  .jssz-txt-top,
  .jssz-txt-bottom-con{
    background-color: #1980FF;
    height: 110px;
    color: #fff;
    font-size: 20px;
    padding: 10px 50px;
  }
  .jssz-txt-top{
    margin-top: 65px;
  }
  .jssz-txt-bottom{
    display: flex;
    margin-top: 40px;
    margin-bottom: 100px;
    justify-content: space-between;
  }
  .jssz-txt-bottom-con{
    width: 480px;
  }
  .desc-item{
    margin-top: 137px;
    line-height: 40px;
    margin-bottom:30px;
  }
</style>
