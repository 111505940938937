<template>
    <div>
      <div class="zpsb-wrap">
        <p class="txt-info">深海救援挑战赛是由推出的旨在锻炼孩子创意设计、编程技能、团队协作和解决问题能力的游戏场景式的挑战赛活动，竞赛以深海故事为导入，要求参赛学员将机器人化身为海洋救援队的一员(创意搭建)，穿越海底的重重障碍(突破险境)，最终将解救的海洋生物送回家(安全返航)。通过创意搭建、突破险境、安全返航三个环节，注重孩子拆解思维、模式识别、抽象思维以及算法思维等解决问题的能力在steam游戏场景竞赛中的培养，并最终达成让孩子在竞技中即收获知识与技能，又提高解决真实场景下复杂问题能力的效果</p>
        <div class="zpsb-info">
          <span class="sb-btn" @click="handleWorksReport">作品申报</span><br>
          <a  class="sb-link" @click="handleOnload">点击附件下载</a><br>
          <span class="sb-time">申报截止时间：2021年10月29日23:59:59</span>
        </div>
      </div>
      <div class="jsdx-wrap">
        <div class="jsdx-content">
         <div style="text-align: center;margin-top: 100px">
           <img src="@/assets/images/csdx_txt.png" alt="">
         </div>
          <p class="desc"><span>各省辖市、济源示范区、直管试点县（市）中小学有正式学籍在校学生（含职专）</span></p>
          <div style="text-align: center;margin-top: 66px">
            <img src="@/assets/images/csdx_img.png"  alt="">
          </div>
          <div  class="jsdx-item-box">
            <div class="jsdx-item">
              <p class="jsdx-txt">小学II组(4-6年级）</p>
            </div>
            <div class="jsdx-item">
              <p class="jsdx-txt">小学I组（1-3年级）</p>
            </div>

          </div>
        </div>
      </div>
      <div>
        <div  style="text-align: center;margin-top: 100px">
          <img src="@/assets/images/jsdt-txt.png" alt="">
        </div>
        <div  style="text-align: center;margin-top: 56px">
          <img src="@/assets/images/map_img.png" alt="">
        </div>

      </div>
      <!--<div class="jsbz-wrap">-->
        <!--<h3 class="sx-set-title jsbz-title">竞赛步骤</h3>-->
        <!--<div class="jsbz-item-wrap">-->
          <!--<div class="jsbz-item" v-for="(item,index) in jsdzItem" :key="index">-->
            <!--<div class="jsbz-item-con">-->
              <!--<div class="jsbz-item-num">{{index+1}}</div>-->
              <!--<h3 class="jsbz-item-title">{{item.title}}</h3>-->
              <!--<div class="jsbz-item-txt">{{item.txt}}</div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <div class="zpyq-wrap">
        <!--<h3 class="sx-set-title">作品要求</h3>-->
        <div style="text-align: center;margin-top: 100px">
          <img src="@/assets/images/lcgz_txt.png"  alt="">
        </div>
        <div class="zpyq-txt-desc">
          <div class="desc-item">
            1.器材摆放与机器调试，时间3分钟。<br>
            选手需将比赛器材摆放至指定区域（图一），海底地图放置比赛区，编程台及模块放置编程区，救援机器人摆放地图起点位置，机器人正面朝向需与地图标注一致，箭头所指为正方向。比赛过程中不得于编程区外进行编程。 机器调试内容：（1）启动救援机器人；（2）机器人是否能够正常移动；（3）地图是否摆放正确，并且平整无异物。如在比赛中因调试原因出现机器故障，则不记录成绩。
          </div>
          <div class="desc-item"> 2.编程赛前准备，时间3分钟。<br> 编程环节开始前，由选手现场随机抽取2个地图中的障碍物摆放位置。（图三）16宫格格子间的空隙编号，横向按字母顺序编号（A-H），纵向按阿拉伯数字顺序编号（1-9），共18个随机障碍物放置区域。选手抽取完成后，经选手和裁判共同确认后摆放到地图上，然后裁判发放编程演示纸，比赛开始之前不允许填写。</div>
          <div class="desc-item">
            3.救援赛：比赛时间10分钟，裁判宣布比赛开始，并开始计时，选手需要先把编程路径写在编程演示纸上，（演示纸每组两张）再进行实物编程，编程完成并确认后，启动机器人进行救援任务。<br>
            （1）任务顺利完成可举手示意裁判，裁判停止计时； <br>（2）救援任务失败，计时继续，选手可以重新调整路径，再次启动救援任务，时间超出10分钟则成绩记为0分。
          </div>
          <!--<p class="desc-item">-->
            <!--（1）“创意改装”任务无需在比赛现场完成，参赛选手需提前提交照片至赛事组委会进行审核，审核通过后只需将改装完的机器人在比赛指定时间带到场地进行检录即可，改装材料可采用黏土、乐高积木、纸板、粘合剂，不可使用除以上规定以外的其它材料-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（2）“突破险境”关卡开始前，裁判现场随机抽取2个地图中的障碍物摆放位置。16宫格格子间的空隙编号，横向按字母顺序编号（A-H），纵向按阿拉伯数字顺序编号（1-10），共18个随机障碍物放置区域-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（3）比赛开始前3分钟，选手需将比赛器材摆放至指定区域，（机器人正面朝向需与地图标注一致），编程台及模块摆放于编程区处，比赛过程中，于编程区外的区域进行编程属于违规行为-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（4）“突破险境”关卡，裁判宣布比赛开始即计时开始，选手通过编程使机器人由海底起点行进到海底终点，途中可经过相关任务点完成指定任务加分，到达终点-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（5）“突破险境”关卡得分任务分为两部分 A、通过正确规划路径，由起点行进至终点； B、行进途中到达任务点-收获珍珠-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（6）机器人行进途中若通过推、撞等行为使障碍物出现明显位移来达到完成任务的目的，则机器人需返回起点重新开始任务；机器人按正确路径行走轻微碰到障碍物属于正常情况不影响比赛进程，队伍需完成“突破险境”关卡到达终点，才可开启“安全返航”关卡-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（7）当YO小兔完成“突破险境”关卡，到达地图终点，选手1举手示意，则选手2开始“安全返航”关卡-->
          <!--</p>-->
          <!--<p class="desc-item">-->
            <!--（8）最终将球送到专属房间内，并将YO小兔送到指定位置，掐表结束，按照所用时间加相应的分数-->
          <!--</p>-->
        </div>
      </div>
      <div class="jssz-wrap">
        <div class="jssz-content">
          <div style="text-align: center;margin-bottom: 30px;">
            <img src="@/assets/images/jssz_txt.png" alt="">
          </div>
          <!--<h3 class="sx-set-title">竞赛赛制</h3>-->
          <!--<p class="desc"><span>比赛为团队赛</span></p>-->
          <div class="jssz-txt-top">
            小组内随机匹配对手进行对抗，获胜得3分，输的得1分，平局各得2分；未到场不得分，对手直接判胜得3分。根据参赛情况，每个队伍都将有3-5次对抗机会，最后计算每队总得分情况，根据得分情况评进行排名。若得分相同，车轻者获胜。具体对阵情况及对阵场次将在比赛前由裁判向选手告知，各选手务必听从裁判指令。
          </div>
          <div class="jssz-txt-bottom">
            <div class="jssz-txt-bottom-con">比赛过程中挂绳及零部件掉落者判负，胜负已分的情况下，挂绳及零件掉落不对比赛结果产生影响。</div>
            <div class="jssz-txt-bottom-con">每局比赛时间为1分钟，1分钟内两车未决出胜负判定为平局；两车僵持不动没有实质性进展的情况下超过5秒判定为平局。</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "thirdSJ",
      data(){
          return {
            jsdzItem:[{
              title:"创意改装",
              txt:"比赛前将YO小兔机器人改装为海底生物，需提前提交改装机器人照片至赛事组委会，且需考虑改装结构对“勇夺桂冠”环节的帮助，比赛全程由改装机器人来完成"
            },{
              title:"突破险境",
              txt:"队伍选手1在指定区域通过实物编程使机器人越过海底的重重困难，由海底起点行进至海底终点，统计选手完成任务的得分及时间"
            },{
              title:"安全返航",
              txt:"队伍选手2在指定区域内控制YO小兔机器人，将球运入自己的“家”，并到达指定区域，掐表结束"
            },{
              title:"统计得分、排名",
              txt:"根据三个环节的综合得分，得出该场比赛的成绩排名"
            }]
          }
      },
      methods:{
        handleWorksReport(){
          //选择判断是否登录，只有登录才能作品申报
          if(window.localStorage.token){
            this.$router.push({ path: '/jingsai/worksReport', query: { param: 3 }})
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
          }
        },
        handleOnload(){
          if(window.localStorage.token){
            window.open(`${this.$axios.iconUrl}/uploads/jingsai/“深海救援”挑战赛.rar`);
          }else{
            this.$message({
              showClose: true,
              message: '请先登录',
              type: 'warning',
              offset:"500"
            });
            return false;
          }
        }
      }
    }
</script>

<style scoped>
  .zpsb-wrap,
  .jsdx-content,
  .jsbz-wrap,
  .jssz-content{
    width:1200px;
    margin:0 auto;
    overflow: hidden;
  }
  .txt-info{
    margin-top: 39px;
    text-indent: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
  }
  .zpsb-info{
    margin-top:24px;
    text-align: center;
    line-height: 50px;
  }
  .sb-btn{
    display: inline-block;
    font-size: 30px;
    margin-bottom: 8px;
    width:300px;
    height:80px;
    line-height: 80px;
    text-align: center;
    color:#fff;
    background-color: #ff7e24;
    border-radius: 10px;
    cursor: pointer;
  }
  .sb-link{
    font-size: 20px;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }
  .sb-link:link{
    border-bottom:1px solid #000;
  }
  .sb-time{
    font-size: 20px;
  }
  .jsdx-wrap{
    background-color: #F4F4F4;
     margin-top: 100px;
  }
  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:140px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .jsdx-content .desc{
    position: relative;
    text-align: center;
    color:#000000;
    font-size: 20px;
  }
  .jsdx-item-box{
    display: flex;
    justify-content: space-around;
    margin-top:20px;
    margin-bottom: 50px;
  }
  .jsdx-item{
    text-align: center;
    width: 400px;
    height: 80px;
    line-height: 80px;
    background-color: #4286EE;
    border-radius: 50px;
  }
  .jsdx-item img{

  }
  .jsdx-item .jsdx-txt{
    color:#FFFFFF;
    font-size: 20px;
  }
  .js-txt-waring{
    color:#1980FF;
    font-size: 20px;
    text-align: center;
    margin-top: 140px;
    padding-bottom: 30px;
  }
  .jsbz-wrap{
    margin-top:100px;
  }
  .jsbz-title{
    height:100px;
  }
  .jsbz-item-wrap{
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
  }
  .jsbz-item{
    width: 280px;
    height: 420px;
    background-color: #1980FF;
    color: #fff;
  }
  .jsbz-item-con{
    width:240px;
    margin: 0 auto;
  }
  .jsbz-item-num{
    margin-top: 30px;
    margin-left: 100px;
    text-align: center;
   font-size: 34px;
   width: 50px;
    height:50px;
    line-height: 50px;
    border-radius: 50%;
    border:1px solid #fff;
  }
  .jsbz-item-title{
    text-align: center;
    margin-top:30px;
   font-size: 34px;
  }
  .jsbz-item-txt{
    margin-top:30px;
   font-size: 20px;
  }
  .tu-wrap{
    margin-top: 100px;
    width: 1920px;
    height:750px;
   background: url("../../../assets/images/xpy.png") no-repeat;
    background-size: 1920px 750px;
    border: 1px solid #fff;
  }
  .tu-content{
    margin:100px auto 0 auto;
    width: 995px;
    height:560px;
    background-color: #fff;
    border-radius: 10px;
  }
  .zpyq-wrap{
    background-image: url("../../../assets/images/zp-bg.png");
    background-color: #f5f5f5;
    margin-top: 80px;
    overflow: hidden;
  }
  .zpyq-wrap  .sx-set-title{
    margin: 0 auto;
    width: 500px;
    height:100px;
    line-height: 100px;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #2D66EA;
  }
  .zpyq-txt-desc{
    width:1200px;
    margin:60px auto;
    color:#000;
    font-size: 20px;
  }
  .desc-item{
    line-height: 30px;
    margin-bottom:30px;
  }
  .jssz-wrap{
    background-color: #fff;
    margin-top: 100px;
    overflow: hidden;
  }
  .jssz-wrap .desc{
    position: relative;
    top: -50px;
    text-align: center;
    color:#000000;
    font-size: 20px;
  }
  .jssz-txt-top,
  .jssz-txt-bottom-con{
    background-color: #1980FF;
    height: 110px;
    color: #fff;
    font-size: 20px;
    padding: 10px 50px;
  }
  .jssz-txt-top{

  }
  .jssz-txt-bottom{
   display: flex;
    margin-top: 40px;
    margin-bottom: 100px;
    justify-content: space-between;
  }
  .jssz-txt-bottom-con{
   width: 480px;
  }
.bg-set-title{
  background-color: #4286EE;
  color: #fff;
}
</style>
