import $axios from '../untils/api';

//注册
export function regInfo(params) {
    return $axios.post('/user/regist',params)
}
//新增客户
export function addUserInfo(params) {
    return $axios.post('/cust/add',params)
}
