<template>
    <div>
      <h3 class="notice-title">
        <span class="title-txt">联系方式</span><br>
        <span class="title-eng">Contact Us </span>
      </h3>
      <div class="contact-wrap">
        <div>
         <div class="contact-item">
           <img src="@/assets/images/qq-img.png" alt="">
           <div class="contact-title">QQ群</div>
           <div class="contact-txt">542415986</div>
          </div>
         <div class="contact-item">
           <img src="@/assets/images/mail-img.png" alt="">
           <div class="contact-title">报名邮箱</div>
           <div class="contact-txt">13403716656@163.com</div>
         </div>
        </div>
        <div>
          <div class="contact-address">
            <img src="@/assets/images/address-img.png" alt="">
            <div class="contact-title">全省决赛报到时间/地址</div>
            <div class="contact-txt small-txt">比赛时间：2021年11月26-28日</div>
            <div class="contact-txt small-txt">比赛地址：郑州市第八十四中学</div>
            <div  class="contact-txt small-txt">（郑州市郑东新区万通街与东周路交叉口）</div>
          </div>
          <div>
            <img src="@/assets/images/map-img.png" alt="">
          </div>
        </div>
        <div>
          <div  class="contact-item">
            <img src="@/assets/images/pho-img.png" alt="">
            <div class="contact-title">联系电话</div>
            <div class="contact-txt">李老师：17839927268
              </div>
            <div class="contact-txt">李老师：18736046965</div>

          </div>
          <div  class="contact-item contact-item-img">
            <img src="@/assets/images/ma.jpg" alt="" width="230" height="260">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>
  .notice-title{
    margin-top: 33px;
    text-align: center;
  }
  .title-txt{
    color: #1980FF;
    font-size: 30px;
  }
  .title-eng{
    position: relative;
    color: #3F3F3F;
    font-size: 20px;
  }
  .title-eng:before{
    content: "";
    position: absolute;
    left: 10px;
    bottom: -10px;
    display: inline-block;
    width: 80px;
    height: 4px;
    background-color: #1980FF;
  }
  .contact-wrap{
    display: flex;
    width: 1200px;
    margin:98px auto 98px auto;
    justify-content: space-between;
  }
  .contact-item{
    text-align: center;
    width: 280px;
    height: 260px;
    background-color: #ECF7FF;
    margin-bottom: 30px;
  }
  .contact-address{
    text-align: center;
    width: 580px;
    height: 275px;
    background-color: #ECF7FF;
  }

  .contact-item img,
  .contact-address img
{
    margin-top: 40px;
  }
  .contact-item-img img{
    margin-top:0;
  }
  .contact-title{
    margin-top: 30px;
    font-weight: bold;
   color: #000;
    font-size: 20px;
  }
  .contact-txt{
    margin-top: 20px;
    color: #000;
    font-size: 18px;
  }
  .small-txt{
    margin-top: 10px;
    font-size: 14px;
  }
</style>
